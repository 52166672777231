import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import http from "../../../../helpers/http";
import * as helpers from "../../../../helpers/api";
import toast, { Toaster } from "react-hot-toast";
import Text from "../../../common/Text";
import { useNavigate } from "react-router-dom";

export default function MaintenancePayForm({ maintenance_row, setPayPopup }) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      hourlyRate: maintenance_row?.offering_rate || "",
      hours_worked: "",
      cost_materials: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "cost_materials",
  });

  const cost_materials = watch("cost_materials");
  const hours_worked = parseInt(watch("hours_worked"));
  const hourlyRate = parseInt(watch("hourlyRate"));
  const [totalCost, setTotalCost] = useState(hourlyRate);
  useEffect(() => {}, [cost_materials]);
  useEffect(() => {}, [hours_worked]);

  const handleValueChange = (index, newValue) => {
    setValue(`cost_materials.${index}.value`, newValue);

    const updatedcost_materials = watch("cost_materials");
    const total = updatedcost_materials.reduce((acc, field) => {
      const value = parseFloat(field.value) || 0;
      const hoursWorked = parseFloat(hours_worked) || 0;
      if (hoursWorked > 0) {
        return acc + value * hoursWorked;
      } else {
        return acc + value;
      }
    }, 0);
    const grand_total = total + hourlyRate;
    setTotalCost(grand_total);
  };
  const handleChangeHours = (newValue) => {
    setValue(`hours_worked`, newValue);
    const updatedcost_materials = cost_materials;
    const total = updatedcost_materials.reduce((acc, field) => {
      const value = parseFloat(field.value) || 0;
      const hoursWorked = parseFloat(newValue) || 0;
      if (hoursWorked > 0) {
        return acc + value * hoursWorked;
      } else {
        return acc + value;
      }
    }, 0);
    const grand_total = total + hourlyRate;
    setTotalCost(grand_total);
  };
  const handleRemove = (index) => {
    remove(index);

    const updatedcost_materials = watch("cost_materials");
    const total = updatedcost_materials.reduce((acc, field) => {
      const value = parseFloat(field.value) || 0;
      const hoursWorked = parseFloat(hours_worked) || 0;
      if (hoursWorked > 0) {
        return acc + value * hoursWorked;
      } else {
        return acc + value;
      }
    }, 0);
    const grand_total = total + hourlyRate;
    setTotalCost(grand_total);
  };
  const addFormBlock = () => {
    append({ id: Date.now(), name: "", value: "" });
  };
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setIsFormProcessing(true);
    http
      .post(
        "staff-update-maintenance-request/" + maintenance_row?.id,
        helpers.doObjToFormData({
          ...data,
          request_id: maintenance_row?.id,
        })
      )
      .then(({ data }) => {
        setIsFormProcessing(false);
        if (data.status) {
          toast.success(data?.msg);
          setTimeout(() => {
            navigate(`/maintenance-request`, {
              state: { refresh: true },
            });
          }, 2000);
        } else {
          toast.error(<Text string={data.msg} parse={true} />);
        }
      })
      .catch((error) => {
        setIsFormProcessing(false);
        toast.error(error);
        return;
      });
  };
  const [CostMaterialtotalCost, setCostMaterialTotalCost] = useState(0);

  useEffect(() => {
    if (maintenance_row?.request_additional_costs?.length > 0) {
      const total = maintenance_row.request_additional_costs.reduce(
        (acc, curr) => {
          return acc + parseFloat(curr?.cost || 0);
        },
        0
      );
      setCostMaterialTotalCost(total);
    }
  }, [maintenance_row?.request_additional_costs]);
  return (
    <>
      <section className="popup" data-popup="staff">
        <Toaster />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={() => setPayPopup(null)}></div>
              {maintenance_row?.hours_worked ? (
                <>
                  <h3>Work Details</h3>
                  <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
                    {maintenance_row?.request_additional_costs?.map(
                      (request_additional_cost, index) => {
                        return (
                          <li>
                            <em>{request_additional_cost?.name}</em>
                            <em>
                              {helpers.format_amount(
                                request_additional_cost?.cost
                              )}
                            </em>
                          </li>
                        );
                      }
                    )}
                    <li>
                      <strong>Total</strong>
                      <strong>{CostMaterialtotalCost}</strong>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <h3>Mark as Completed</h3>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="new_form_new"
                  >
                    <div className="form_row row flex">
                      <div className="col-xs-6">
                        <h6>Hourly Rate</h6>
                        <div className="form_blk">
                          <input
                            type="text"
                            id="hourlyRate"
                            name="hourlyRate"
                            className="txtBox"
                            disabled
                            {...register("hourlyRate")}
                          />
                          <span className="dollar_label">$</span>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <h6>Hours Worked</h6>
                        <div className="form_blk">
                          <input
                            type="number"
                            id="hours_worked"
                            name="hours_worked"
                            className="txtBox"
                            {...register("hours_worked", {
                              required: "Hours Worked is required",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message:
                                  "Please enter a valid number with up to two decimal places",
                              },
                            })}
                            onChange={(e) => handleChangeHours(e.target.value)}
                          />
                          {errors.hours_worked && (
                            <p className="error">
                              {errors.hours_worked.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <h6>Cost of Materials</h6>
                        <div className="form_blk">
                          {fields.map((field, index) => (
                            <div
                              className="new_blk_cost_add new_clr"
                              key={field.id}
                            >
                              <div className="inner_blk flex over_flow_unset">
                                <div
                                  className="closeBtn"
                                  onClick={() => handleRemove(index)}
                                ></div>
                                <div className="inner_colR">
                                  <div className="padding-right-must">
                                    <input
                                      type="text"
                                      className="txtBox"
                                      placeholder="Name"
                                      {...register(
                                        `cost_materials.${index}.name`,
                                        {
                                          required: true,
                                        }
                                      )}
                                    />
                                    {errors.cost_materials &&
                                      errors.cost_materials[index] &&
                                      errors.cost_materials[index].name && (
                                        <p className="error">
                                          Name is required
                                        </p>
                                      )}
                                  </div>
                                </div>
                                <div className="inner_colL">
                                  <div className="padding-left-must">
                                    <input
                                      type="text"
                                      className="txtBox"
                                      placeholder="Value"
                                      {...register(
                                        `cost_materials.${index}.value`,
                                        {
                                          required: "Value is required",
                                          pattern: {
                                            value: /^\d+(\.\d{1,2})?$/,
                                            message:
                                              "Please enter a valid number with up to two decimal places",
                                          },
                                        }
                                      )}
                                      onChange={(e) =>
                                        handleValueChange(index, e.target.value)
                                      }
                                    />
                                    {errors.cost_materials &&
                                      errors.cost_materials[index] &&
                                      errors.cost_materials[index].value && (
                                        <p className="error">
                                          {
                                            errors.cost_materials[index].value
                                              .message
                                          }
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="bTn text-center pt_2">
                          <button
                            className="colorBtn webBtn mini_web_btn"
                            type="button"
                            onClick={addFormBlock}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <br />
                        <h5>
                          Total Cost: <strong>${totalCost.toFixed(2)}</strong>
                        </h5>
                        <br />
                      </div>
                      <div className="col-xs-12">
                        <div className="bTn text-center">
                          <button
                            className="webBtn"
                            type="submit"
                            disabled={isFormProcessing}
                          >
                            Mark as complete{" "}
                            {isFormProcessing ? (
                              <i className="spinner"></i>
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
