import React from 'react'
import { NavLink } from 'react-router-dom';
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Select from "react-select";

export default function SaleListingStep1({ step, changeStep, register, errors, control, watchAllFields, isValid, trigger }) {
    let hoa_fee_frequency_options = [
        {
            label: "Not Applicable",
            value: "Not Applicable"
        },
        {
            label: "Daily",
            value: "Daily"
        },
        {
            label: "Biweekly",
            value: "Biweekly"
        },
        {
            label: "Monthly",
            value: "Monthly"
        },
        {
            label: "Quarterly",
            value: "Quarterly"
        },
        {
            label: "Semiannually",
            value: "Semiannually"
        },
        {
            label: "Yearly",
            value: "Yearly"
        },
    ];

    return (
        <>
            <fieldset>
                <div className="form_row row flex">
                    <div className="col-xs-6">
                        <h6>Price</h6>
                        <div className="form_blk deposit_form_blk">
                            <input type="text" name="price" id="price" className="txtBox"  {...register("price", {

                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Invalid value',
                                },
                                required: "Required",



                            })} />
                            <span className='dollar_label'>$</span>
                            <ErrorMessage
                                errors={errors}
                                name="price"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">

                    </div>
                    <div className="col-xs-6">
                        <h6>HOA Fee</h6>
                        <div className="form_blk deposit_form_blk">
                            <input type="text" name="hoa_fee" id="hoa_fee" className="txtBox"   {...register("hoa_fee", {

                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Invalid value',
                                },
                                required: "Required",



                            })} />
                            <span className='dollar_label'>$</span>
                            <ErrorMessage
                                errors={errors}
                                name="hoa_fee"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <h6>HOA Fee Frequency</h6>
                        <div className="form_blk">
                            <Controller
                                {...register("hoa_fee_freq", { required: "Required" })}
                                name="hoa_fee_freq"
                                control={control}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <>
                                        <Select
                                            options={hoa_fee_frequency_options}
                                            onChange={val => {
                                                onChange(val.value);
                                            }}
                                            label="Select"
                                        />
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="hoa_fee_freq"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <h6>Condo Fee</h6>
                        <div className="form_blk deposit_form_blk">
                            <input type="text" name="condo_fee" id="condo_fee" className="txtBox" {...register("condo_fee", {

                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Invalid value',
                                },
                                required: "Required",



                            })} />
                            <span className='dollar_label'>$</span>
                            <ErrorMessage
                                errors={errors}
                                name="condo_fee"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <h6>Condo Fee Frequency</h6>
                        <div className="form_blk">
                            <Controller
                                {...register("condo_fee_freq", { required: "Required" })}
                                name="condo_fee_freq"
                                control={control}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <>
                                        <Select
                                            options={hoa_fee_frequency_options}
                                            onChange={val => {
                                                onChange(val.value);
                                            }}
                                            label="Select"
                                        />
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="condo_fee_freq"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                </div>
                <div className="cmnBtn text-center">
                    <NavLink to="/listing" className="webBtn backBtn">Previous</NavLink>
                    {
                        !isValid ?
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => {
                                trigger();
                            }} >Next</button>
                            :
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)} disabled={!isValid}>Next</button>
                    }
                </div>
            </fieldset>
        </>
    )
}
