import React from "react";

export default function MaintenanceRequestSort({
  tabType,
  setTabType,
  my_requests,
  received,
  assigned,
  tenant_properties,
  received_properties,
  assigned_properties,
  handlePropertyChange,
  propertyId
}) {
  return (
    <div className="flterFlexListing flex">
      <ul class="nav nav-tabs">
        {received > 0 ? (
          <li class={tabType === "received" ? "active" : ""}>
            <a
              class="a"
              data-toggle="tab"
              href="#received_request"
              onClick={() => setTabType("received")}
            >
              Received Requests <span>({received})</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {my_requests > 0 ? (
          <li class={tabType === "my_requests" ? "active" : ""}>
            <a
              class="b"
              data-toggle="tab"
              href="#my_request"
              onClick={() => setTabType("my_requests")}
            >
              My Requests <span>({my_requests})</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {assigned > 0 ? (
          <li class={tabType === "assigned" ? "active" : ""}>
            <a
              class="b"
              data-toggle="tab"
              href="#my_request"
              onClick={() => setTabType("assigned")}
            >
              Assigned Requests <span>({assigned})</span>
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
      {
        received > 0 || assigned > 0 || my_requests > 0 ?
          <div class="filter_status_offer">
            <div class="col_ofer_filter">
              <div class="sorted_heading">
                <span>Property :</span>
              </div>
              <div class="sorted_drop_down">
                <select name="property_id" id="property_id" class="txtBox" onChange={handlePropertyChange}>
                  <option value="all">All</option>
                  {
                    tabType === 'received' ?
                      received_properties?.map((tenant_property, index) => {
                        return (
                          <option value={tenant_property?.id} key={index} selected={propertyId === tenant_property?.id ? true : false}>{tenant_property?.title}</option>
                        )
                      })
                      :
                      tabType === 'assigned' ?
                        assigned_properties?.map((tenant_property, index) => {
                          return (
                            <option value={tenant_property?.id} key={index} selected={propertyId === tenant_property?.id ? true : false}>{tenant_property?.title}</option>
                          )
                        })
                        :
                        tabType === 'my_requests' ?
                          tenant_properties?.map((tenant_property, index) => {
                            return (
                              <option value={tenant_property?.id} key={index} selected={propertyId === tenant_property?.id ? true : false}>{tenant_property?.title}</option>
                            )
                          })
                          :
                          ""
                  }
                </select>
              </div>
            </div>
          </div>
          :
          ""
      }
    </div>
  );
}
