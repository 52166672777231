import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getSelectedValue } from "../../../../helpers/api";
import Select from "react-select";
import moment from "moment";
import InputMask from "react-input-mask";

export default function RentalListingStep3({
  step,
  changeStep,
  register,
  errors,
  isValid,
  control,
  siteSettings,
  trigger,
  member,
  checkBirthDate,
  watchAllFields,
  dobError,
  setValue,
}) {
  // useEffect(() => {

  //     setValue("state_label", getSelectedValue("state"));
  // }, [])

  return (
    <>
      <fieldset>
        <div className="form_row row justify_center_flex">
          <div className="col-xs-12">
            <div className="form_row row">
              <div className="col-xs-4">
                <h6>First Name</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    className="txtBox"
                    {...register("fname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                    })}
                    defaultValue={watchAllFields?.fname}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fname"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Middle Name</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="mname"
                    id="mname"
                    className="txtBox"
                    {...register("mname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                    })}
                    defaultValue={watchAllFields?.mname}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="mname"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Last Name</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    className="txtBox"
                    {...register("lname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                    })}
                    defaultValue={watchAllFields?.lname}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lname"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Date of Birth</h6>
                <div className="form_blk">
                  {/* {
                                        watchAllFields?.dob !== undefined && watchAllFields?.dob !== null && watchAllFields?.dob !== '' ?
                                            <Controller
                                                control={control}
                                                name="dob"
                                                defaultValue={watchAllFields?.dob !== undefined && watchAllFields?.dob !== null && watchAllFields?.dob !== '' ? new Date(watchAllFields?.dob) : new Date()}
                                                {...register("dob", { required: "Required" })}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className="txtBox"
                                                        placeholderText="_ _ / _ _ / _ _ _ _"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={new Date(field.value)}
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                            />
                                            :
                                            <Controller
                                                control={control}
                                                name="dob"
                                                {...register("dob", { required: "Required" })}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className="txtBox"
                                                        placeholderText="_ _ / _ _ / _ _ _ _"
                                                        onChange={(date) => field.onChange(date)}
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                            />
                                    } */}
                  <InputMask
                    mask="99-99-9999"
                    placeholder="mm-dd-yyyy"
                    name="date"
                    id="date"
                    className="txtBox"
                    autoComplete="none"
                    {...register("dob", { required: "Required" })}
                    defaultValue={
                      watchAllFields?.dob !== undefined &&
                      watchAllFields?.dob !== null &&
                      watchAllFields?.dob !== ""
                        ? moment(watchAllFields?.dob).format("MM-DD-YYYY")
                        : moment(new Date()).format("MM-DD-YYYY")
                    }
                  />

                  {watchAllFields?.dob !== undefined &&
                  watchAllFields?.dob !== null &&
                  watchAllFields?.dob !== "" &&
                  dobError !== false ? (
                    <p className="error">
                      <i className="fi-warning"></i> {dobError}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <InputMask mask="99-99-9999" name="dob" id="dob" className='txtBox' autoComplete="none"  {...register("dob", {
                                        required: "Required"
                                    })} /> */}
                  <ErrorMessage
                    errors={errors}
                    name="dob"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-12">
                <h6>Address Line 1</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="address_line1"
                    id="address_line1"
                    className="txtBox"
                    {...register("address_line1", { required: "Required" })}
                    defaultValue={watchAllFields?.address_line1}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="address_line1"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-12">
                <h6>Address Line 2</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="address_line2"
                    id="address_line2"
                    className="txtBox"
                    {...register("address_line2")}
                    defaultValue={watchAllFields?.address_line2}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>City</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="txtBox"
                    {...register("city", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                    })}
                    defaultValue={watchAllFields?.city}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>State</h6>
                <div className="form_blk">
                  <Controller
                    {...register("state", { required: "Required" })}
                    name="state"
                    id="state"
                    control={control}
                    defaultValue={watchAllFields?.state}
                    render={({ field: { onChange, value, ref, name } }) => (
                      <>
                        <Select
                          options={siteSettings?.states}
                          onChange={(val) => {
                            onChange(val.value);
                            setValue("state_label", val.label);
                          }}
                          label="Select State"
                          defaultValue={siteSettings?.states.filter(function (
                            option
                          ) {
                            return option.value === value;
                          })}
                        />
                      </>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="state"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>ZIP Code</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="zip_code"
                    id="zip_code"
                    className="txtBox"
                    {...register("zip_code", {
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                      minLength: {
                        value: 5,
                        message: "Zip code should be  equal to 5 digits",
                      },
                      maxLength: {
                        value: 5,
                        message: "Zip code should be  equal to 5 digits",
                      },
                    })}
                    defaultValue={watchAllFields?.zip_code}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="zip_code"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => checkBirthDate()}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
