import { Helmet } from "react-helmet";
import http from "./http";
import parse from "html-react-parser";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../utils/siteSettings";

let project_url = "";
let project_ap_url = "";
let hostname = window.location.hostname;
if (hostname === "localhost") {
  project_url = "http://localhost:3000/";
  // project_ap_url = "http://127.0.0.1:8000/";
  project_ap_url = process.env.REACT_APP_API_URL;
} else {
  project_url = process.env.REACT_APP_URL;
  project_ap_url = process.env.REACT_APP_API_URL;
}
export function isSameMonth(targetDate) {
  if (targetDate !== undefined && targetDate !== null && targetDate !== "") {
    // Parse the target date using Moment.js
    const momentTargetDate = moment(targetDate);

    // Get the current date
    const currentDate = moment();

    // Compare the month of the target date with the month of the current date
    const result = momentTargetDate.month() === currentDate.month();
    if (result) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}
export function calculatePercentageOfAmount(originalAmount, percentage) {
  const amount = parseFloat(originalAmount);
  const percent = parseFloat(percentage);

  // Validate input
  if (isNaN(amount) || isNaN(percent)) {
    return false;
  }

  return ((amount * percent) / 100).toFixed(2);
}
export function convertSimpleDateUsingMoment(dateString) {
  moment.tz.setDefault("America/New_York");
  const dateMoment = moment(dateString);
  return dateMoment.format("YYYY-MM-DD");
}
export function hasDatePassed(targetDate) {
  // Parse the target date using Moment.js
  const momentTargetDate = moment(targetDate);
  // Get the current date
  const currentDate = moment();

  // Compare the target date with the current date
  const result = momentTargetDate.isBefore(currentDate);
  if (result) {
    return true;
  } else {
    return false;
  }
}
export function comercialSearchParams(searchParams) {
  let min_price =
    searchParams.get("min_price") !== null ? searchParams.get("min_price") : 0;
  let max_price =
    searchParams.get("max_price") !== null
      ? searchParams.get("max_price")
      : 100000000;
  let amenities =
    searchParams.get("amenities") !== null &&
    searchParams.get("amenities")?.length > 0
      ? searchParams.get("amenities").split(",")
      : [];
  let features =
    searchParams.get("features") !== null &&
    searchParams.get("features")?.length > 0
      ? searchParams.get("features").split(",")
      : [];

  let sq_ft = searchParams.get("sq_ft");
  let laundry =
    searchParams.get("laundry") !== null ? searchParams.get("laundry") : "";
  let min_beds =
    searchParams.get("min_beds") !== null ? searchParams.get("min_beds") : 0;
  let max_beds =
    searchParams.get("max_beds") !== null ? searchParams.get("max_beds") : 10;
  let min_baths =
    searchParams.get("min_baths") !== null ? searchParams.get("min_baths") : 0;
  let max_baths =
    searchParams.get("max_baths") !== null ? searchParams.get("max_baths") : 10;
  return {
    min_beds: min_beds,
    max_beds: max_beds,
    min_baths: min_baths,
    max_baths: max_baths,
    min_price: min_price,
    max_price: max_price,
    amenities: amenities !== null && amenities?.length > 0 ? amenities : [],
    features: features !== null && features?.length > 0 ? features : [],
    sq_ft: sq_ft,
    laundry: laundry,
  };
}
export function doObjToFormData(obj) {
  var formData = new FormData();
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        formData.append(key + "[]", JSON.stringify(value));
      }
    } else {
      if (typeof obj[key] == "object") {
        formData.append(key, JSON.stringify(obj[key]));
      } else {
        formData.append(key, obj[key]);
      }
    }
  }
  return formData;
}
const headers = {
  "Content-Type": "application/json",
  // Authorization: token,
};
const token = localStorage.getItem("authToken");
const post_headers = {
  "Content-Type": "application/json",
  Authorization: token,
};
export async function projectImageUpload(event, type = "floor_plans") {
  try {
    const fd = new FormData();
    fd.append("image", event);
    fd.append("type", type);
    return axios
      .post(project_ap_url + "api/" + "upload-image", fd)
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    // If there's an error, handle it here
    console.error("Error uploading image:", error);
    // You can also throw the error to propagate it to the caller
    // throw error;
  }
}
export async function upload_file(event) {
  const fd = new FormData();
  fd.append("file", event);
  return axios.post(project_ap_url + "api/" + "upload-file", fd).then((res) => {
    return res.data;
  });
}
export async function uploadBillingCsv(event) {
  const fd = new FormData();
  fd.append("file", event);
  return axios
    .post(project_ap_url + "api/" + "save-billing-csv", fd, {
      headers: post_headers,
    })
    .then((res) => {
      return res.data;
    });
}
export async function getBranchFloorPlans(branch_id) {
  const fd = new FormData();
  fd.append("branch_id", branch_id);
  return http
    .post(project_ap_url + "api/" + "get-floor-plans", fd)
    .then((res) => {
      return res.data;
    });
}
export async function postExamAnswers(data, apiUrl) {
  const frmData = objToFormData(data);
  return http.post(project_ap_url + apiUrl, frmData).then((res) => {
    return res.data;
  });
}
export async function getOtpExpiryDate() {
  await getData("api/site-settings", token).then((data) => {});
}
export async function getBranchProperties(branch_id) {
  const fd = new FormData();
  fd.append("branch_id", branch_id);
  return http
    .post(project_ap_url + "api/" + "get-properties", fd)
    .then((res) => {
      return res.data;
    });
}
export async function getBranchPropertyLeases(branch_id, property_id) {
  const fd = new FormData();
  fd.append("branch_id", branch_id);
  fd.append("property_id", property_id);
  return http
    .post(project_ap_url + "api/" + "get-property-leases", fd)
    .then((res) => {
      return res.data;
    });
}
export async function getBranchFloorPlanProperties(branch_id, floor_plan) {
  const fd = new FormData();
  fd.append("branch_id", branch_id);
  fd.append("floor_plan", JSON.stringify(floor_plan));
  return http
    .post(project_ap_url + "api/" + "get-branch-floor-plan-properties", fd)
    .then((res) => {
      return res.data;
    });
}
export async function getExistingListing(data) {
  return http
    .post(project_ap_url + "api/" + "get-existing-listings", data)
    .then((res) => {
      return res.data;
    });
}
export async function getBranchListings(branch_id) {
  const fd = new FormData();
  fd.append("branch_id", branch_id);
  return http.post(project_ap_url + "api/" + "get-listings", fd).then((res) => {
    return res.data;
  });
}
export async function checkPropertyAddressAvailability(data) {
  const frmData = objToFormData(data);
  return http
    .post(
      project_ap_url + "api/" + "check-property-address-availability",
      frmData
    )
    .then((res) => {
      consoleLog(res.data);
      return res.data;
    });
}
export async function checkPropertyTitleAvailability(data, property_id) {
  const frmData = objToFormData(data);
  if (
    property_id !== undefined &&
    property_id !== null &&
    property_id > 0 &&
    property_id !== ""
  ) {
    frmData.append("property_id", property_id);
  } else {
    frmData.append("property_id", "");
  }
  return http
    .post(
      project_ap_url + "api/" + "check-property-title-availability",
      frmData
    )
    .then((res) => {
      // consoleLog(res.data)
      return res.data;
    });
}
export async function checkBranchTitleAvailability(data, branch_id) {
  const frmData = objToFormData(data);
  if (
    branch_id !== undefined &&
    branch_id !== null &&
    branch_id > 0 &&
    branch_id !== ""
  ) {
    frmData.append("branch_id", branch_id);
  } else {
    frmData.append("branch_id", "");
  }
  return http
    .post(project_ap_url + "api/" + "check-branch-title-availability", frmData)
    .then((res) => {
      // consoleLog(res.data)
      return res.data;
    });
}
export function getPackageName(pkg) {
  if (pkg === "N") {
    return "None";
  } else if (pkg === "CC") {
    return "Credit & Criminal";
  } else if (pkg === "CCE") {
    return "Credit & Criminal and Evictions";
  } else if (pkg === "CCI") {
    return "Credit & Criminal and ID";
  } else if (pkg === "CCEI") {
    return "Credit & Criminal, Evictions, and ID";
  } else {
    return "None";
  }
}
export function isPackageUpgraded(package_names, property_row, watchAllFields) {
  if (
    package_names !== undefined &&
    package_names !== null &&
    package_names?.length > 0
  ) {
    if (property_row?.screening === "CC") {
      if (package_names.includes("CCE") && watchAllFields?.package === "CC") {
        return false;
      } else if (
        package_names.includes("CCI") &&
        watchAllFields?.package === "CC"
      ) {
        return false;
      } else if (
        package_names.includes("CCE") &&
        watchAllFields?.package === "CCE"
      ) {
        return false;
      } else if (
        package_names.includes("CCI") &&
        watchAllFields?.package === "CCI"
      ) {
        return false;
      } else if (
        package_names.includes("CCE") &&
        package_names.includes("CCI") &&
        watchAllFields?.package === "CC"
      ) {
        return false;
      } else if (
        package_names.includes("CCE") &&
        package_names.includes("CCI") &&
        watchAllFields?.package === "CCE"
      ) {
        return false;
      } else if (
        package_names.includes("CCE") &&
        package_names.includes("CCI") &&
        watchAllFields?.package === "CCI"
      ) {
        return false;
      } else if (
        package_names.includes("CCE") &&
        package_names.includes("CCI") &&
        watchAllFields?.package === "CCEI"
      ) {
        return false;
      } else {
        return true;
      }
    } else if (property_row?.screening === "CCI") {
      if (package_names.includes("CCEI") && watchAllFields?.package === "CCI") {
        return false;
      } else if (
        package_names.includes("CCI") &&
        watchAllFields?.package === "CCI"
      ) {
        return false;
      } else {
        return true;
      }
    } else if (property_row?.screening === "CCE") {
      if (package_names.includes("CCEI") && watchAllFields?.package === "CCE") {
        return false;
      } else if (
        package_names.includes("CCE") &&
        watchAllFields?.package === "CCE"
      ) {
        return false;
      } else {
        return true;
      }
    } else if (property_row?.screening === "CCEI") {
      if (
        package_names.includes("CCEI") &&
        watchAllFields?.package === "CCEI"
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
}
export function getPackagePrice(pkg, site_package_cost) {
  if (pkg === "N") {
    return 0;
  }
  // else if (pkg === 'CC') {
  //     return 30;
  // }
  // else if (pkg === 'CCE') {
  //     return 34;
  // }
  // else if (pkg === 'CCI') {
  //     return 35;
  // }
  else if (pkg === "CCEI") {
    return parseFloat(site_package_cost);
  } else {
    return 0;
  }
}
export function current_date(date) {
  const nDate = new Date(date).toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  return nDate;
}
export function convertToDashesDate(date) {
  if (date !== "" && date !== undefined && date !== null) {
    let availabledateVal = new Date(date);
    return (
      parseInt(availabledateVal.getFullYear()) +
      "-" +
      parseInt(parseInt(availabledateVal.getMonth()) + 1) +
      "-" +
      availabledateVal.getDate()
    );
  }
}
export function convertToSlashesDate(date) {
  if (date !== "" && date !== undefined && date !== null) {
    let availabledateVal = new Date(date);
    return (
      parseInt(parseInt(availabledateVal.getMonth()) + 1) +
      "/" +
      availabledateVal.getDate() +
      "/" +
      parseInt(availabledateVal.getFullYear())
    );
  }
}

export async function uploadImages(event, type) {
  let newImages = [];
  let images_arr = event.target.files;
  for (let i = 0; i < images_arr.length; i++) {
    let fileSize = images_arr[i].size;
    let sizeMb = bytesToMegaBytes(fileSize);
    if (sizeMb < 40) {
      let image = await projectImageUpload(images_arr[i], type).then((data) => {
        if (data.image_name != undefined) {
          newImages.push({ file_name: data.image_name });
        }
      });
    }
  }
  return newImages;
}
export async function FileUpload(event, type = "attchments") {
  const fd = new FormData();
  fd.append("file", event);
  fd.append("type", type);
  return axios.post(project_ap_url + "api/" + "upload-file", fd).then((res) => {
    return res.data;
  });
}
export async function uploadMultiFiles(event, type) {
  let newImages = [];
  let images_arr = event.target.files;
  for (let i = 0; i < images_arr.length; i++) {
    let fileSize = images_arr[i].size;
    let sizeMb = bytesToMegaBytes(fileSize);
    if (sizeMb < 40) {
      let image = await FileUpload(images_arr[i], type).then((data) => {
        //
        if (data.file_name != undefined && data?.status === 1) {
          newImages.push({
            file_name: data.file_name,
            image_name: data?.file_name_text,
          });
        } else if (data?.status === 0) {
          toast.error(data?.msg, TOAST_SETTINGS);
          return;
        }
      });
    }
  }
  return newImages;
}
export function objToFormData(obj) {
  var form_data = new FormData();
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        form_data.append(key + "[]", JSON.stringify(value));
      }

      //form_data.append(key + "[]", obj[key]);
    } else {
      if (typeof obj[key] == "object") {
        form_data.append(key, JSON.stringify(obj[key]));
      } else {
        form_data.append(key, obj[key]);
      }
    }
  }
  return form_data;
}
export function projectUrl(path) {
  return project_url + path;
}
export function getSelectedValue(id) {
  var e = document.getElementById(id);

  if (e != undefined && e !== null) {
    return e.options[e.selectedIndex].text;
  }
}
export function shareFacebook(url, title) {
  window.open(
    "https://www.facebook.com/share.php?u=" + url + "&title=" + title,
    "sharer",
    "toolbar=0,status=0,width=548,height=325,top=170,left=400"
  );
}
export function shareLinkedin(url, title, text, site_name) {
  window.open(
    "https://www.linkedin.com/shareArticle?mini=true&url=" +
      url +
      "&title=" +
      title +
      "&summary=" +
      text +
      "&source=" +
      site_name
  );
}
export function shareTwitter(url, title) {
  window.open("https://twitter.com/intent/tweet?text=" + title + "&url=" + url);
}
export function shareGoogle(url, title) {
  window.open(
    "https://plus.google.com/share?url=" + url + "&title=" + title,
    "sharer",
    "toolbar=0,status=0,width=548,height=325,top=170,left=400"
  );
}
export function sharePinterest(url, image, title) {
  window.open(
    "https://pinterest.com/pin/create/button/?url=" +
      url +
      "&media=" +
      image +
      "&description=" +
      title,
    "sharer",
    "toolbar=0,status=0,width=548,height=325,top=170,left=400"
  );
}
export function shareWhatsapp(title) {
  document.location = "whatsapp://send?text=" + title;
}
export function format_amount(x) {
  if (x !== undefined) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + parts.join(".");
  } else {
    return "$0.00";
  }
}
export function format_number(x) {
  if (x !== undefined) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return "";
  }
}
export function metaTags(page) {
  return (
    <Helmet>
      <title>{page.page_name}</title>
      <meta name="title" content={page.meta_title} />
      <meta name="description" content={page.meta_description} />
      <link rel="canonical" href={project_url + page.page_url} />
      <meta property="og:url" content={project_url + page.page_url} />
      <meta property="og:title" content={page.meta_title} />
      <meta property="og:description" content={page.meta_description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={page.site_name} />
      <meta property="og:image" content={page.site_thumbnail} />
    </Helmet>
  );
}
export function getSiteImages(path) {
  return project_url + path;
}
export function checkPattern(string, pattern) {
  return pattern.test(string);
}
export async function postData(method, data, parameters = null) {
  let u;
  parameters === null
    ? (u = project_ap_url + method)
    : (u = project_ap_url + method + "/" + parameters);

  return axios
    .post(u, data, {
      headers: post_headers,
    })
    .then((res) => {
      return res.data;
    });
}
export function getYoutubeVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}
export function bytesToMegaBytes(bytes) {
  return bytes / (1024 * 1024);
}
export function split_string(str, symbol) {
  var ar = str.split(symbol);
  return ar;
}
export function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
export function imageValidation(files) {
  let res = [];
  let fileSize = files.size;
  let image_type = split_string(files.type, "/");
  if (image_type[0] === "image") {
    if (image_type[1] === "webp") {
      res["error"] =
        "Only images(jpg,jpeg,png,svg,gif) are allowed to upload. The file type you are trying to upload is not allowed.";
      return res;
    } else {
      let sizeMb = bytesToMegaBytes(fileSize);
      if (sizeMb > 20) {
        res["error"] = "The Image size is too big. Allowed size is 20MB.";
        return res;
      }
    }
  } else if (image_type[1] === "webp") {
    res["error"] =
      "Only images(jpg,jpeg,png,svg,gif) are allowed to upload. The file type you are trying to upload is not allowed.";
    return res;
  } else {
    res["error"] =
      "Only images(jpg,jpeg,png,svg,gif) are allowed to upload. The file type you are trying to upload is not allowed.";
    return res;
  }
  return res;
}
export function fileValidation(files) {
  let res = [];
  let fileSize = files.size;
  let sizeMb = bytesToMegaBytes(fileSize);
  if (sizeMb > 20) {
    res["error"] = "The File size is too big. Allowed size is 20MB.";
    return res;
  }
  return res;
}
export function removeArrayItem(myArray, myElement) {
  for (var i = myArray.length - 1; i >= 0; i--) {
    if (myArray[i] == myElement) myArray.splice(i, 1);
  }
  return myArray;
}
export function convertDateOnly(date) {
  var newDate = new Date(date);
  let str_date = newDate.toDateString();
  return str_date;
}
export function getMonthName(monthNumber) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Invalid Month";
  }
}
export function getDateFromCalendarDate(inputDateString, format) {
  const inputDate = new Date(inputDateString);

  // Extract year, month, and day components
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(inputDate.getDate()).padStart(2, "0");

  // Format the output string
  return `${year}/${month}/${day}`;
}
export function format_date(date) {
  moment.tz.setDefault("America/New_York");
  return moment(date).format("MMMM D, YYYY");
}
export function format_date_simple(date, format = "MM/DD/YYYY") {
  moment.tz.setDefault("America/New_York");
  return moment(date).format(format);
}
export function new_moment_date() {
  moment.tz.setDefault("America/New_York");
  return moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
}
export function format_date_time(date) {
  moment.tz.setDefault("America/New_York");
  const formattedDate = moment(date).format("MMMM D, YYYY");
  const formattedTime = moment(date).format("h:mm a");

  return formattedDate + " " + formattedTime;
}
export function moment_new_date(date, time = true) {
  moment.tz.setDefault("America/New_York");
  if (time) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return moment(date).format("YYYY-MM-DD");
  }
}
export function downloadCSV(data, filename) {
  const csvContent =
    "data:text/csv;charset=utf-8," +
    data
      .map((row) =>
        row
          .map(
            (value) => `"${String(value).replace(/"/g, '""')}"` // Escape double quotes in the value
          )
          .join(",")
      )
      .join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename + ".csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function datepickerDateGet(date) {
  moment.tz.setDefault("America/New_York");
  return moment(date).toDate();
}
let is_console = true;
export function consoleLog(item) {
  if (is_console) {
    console.log(item);
  }
}
export function next_month_date() {
  const currentUSDate = moment.tz("America/New_York");
  const nextMonthStartDate = currentUSDate
    .clone()
    .add(1, "months")
    .startOf("month");
  return nextMonthStartDate.toDate();
}
export function moment_new_date_for_edit(date) {
  moment.tz.setDefault("America/New_York");
  return moment(date).toDate();
}
export function short_text(text, length = 25) {
  if (text.length > length) {
    let str = text.substring(0, length);
    return str + "...";
  } else {
    return text;
  }
}
export function calculateTimeAgo(dateTime) {
  const now = moment();
  const postedTime = moment(dateTime);
  const diff = now.diff(postedTime);

  const duration = moment.duration(diff);

  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())} second${
      duration.asSeconds() < 2 ? "" : "s"
    } ago`;
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())} minute${
      duration.asMinutes() < 2 ? "" : "s"
    } ago`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())} hour${
      duration.asHours() < 2 ? "" : "s"
    } ago`;
  } else {
    return `${Math.floor(duration.asDays())} day${
      duration.asDays() < 2 ? "" : "s"
    } ago`;
  }
}
export function replaceSpaceWith20(string) {
  string = string.replaceAll("_", " ");
  string = string.replaceAll(",", " ");
  string = string.replaceAll("#", " ");
  return string.replace(/ /g, "%20");
}
export function formValidation(name, value, password) {
  let validate_arr = [];
  let error = [];
  let error_msg = "";
  if (name !== "" && value !== "") {
    let pattern = "";
    let pattern_msg = "";

    if (name === "email") {
      pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      pattern_msg = "Invalid e-mail address";
    } else if (name === "phone") {
      pattern = /^[0-9-]+$/;
      pattern_msg = "Invalid phone number";
    } else if (name === "password") {
      if (value !== password) {
        error_msg = "Password does not match";
      } else {
        pattern =
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/;
        pattern_msg =
          "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit.";
      }
    }
    if (pattern != "") {
      let check = checkPattern(value, /^[0-9-]+$/);
      if (check === false) {
        if (pattern_msg !== "") {
          error[name] = pattern_msg;
          // validate_arr['error'] = error;
          validate_arr["status"] = 0;
          validate_arr["error"] = { msg: pattern_msg, name: name };
          return validate_arr;
        } else {
          error[name] = "Invalid " + name;
          // validate_arr['error'] = error;
          validate_arr["status"] = 0;
          validate_arr["error"] = { msg: "Invalid " + name, name: name };
          return validate_arr;
        }
      } else {
        error[name] = "";
        // validate_arr['error'] = error;
        validate_arr["status"] = 1;
        validate_arr["error"] = { msg: "", name: name };
        return validate_arr;
      }
    }

    error[name] = "";
    // validate_arr['error'] = error;
    validate_arr["status"] = 1;
    validate_arr["error"] = { msg: "", name: name };
    return validate_arr;
  } else {
    let error_msg = "";
    if (error_msg != "") {
      error_msg = error_msg;
    } else {
      error_msg = name + " is Required ";
    }
    // validate_arr['error'] = error;
    validate_arr["status"] = 0;
    validate_arr["error"] = { msg: error_msg, name: name };
    return validate_arr;
  }
}
export function checkDuplicatesInArr(array) {
  const uniqueIds = [];
  const uniqueArray = array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id);

    if (!isDuplicate) {
      uniqueIds.push(element.id);

      return true;
    }

    return false;
  });
  return uniqueArray;
}
export function checkUrl(url) {
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}
export function getRemainder(value) {
  return parseFloat(value % 2);
}
export function getServerImage(path, image = null, type = null) {
  if (image === null || image === "") {
    return project_url + "images/no-image.svg";
  } else {
    if (type == null || type === "") {
      return project_ap_url + path + image;
    } else {
      if (fileExtension(image) === "svg") {
        return project_ap_url + path + image;
      } else {
        return project_ap_url + path + type + image;
      }
    }
  }
}
export function getServerVideo(path, image = null, type = null) {
  if (image === null || image === "") {
    return project_url + "videos/404.mp4";
  } else {
    if (type == null || type === "") {
      return project_ap_url + path + image;
    } else {
      if (fileExtension(image) === "svg") {
        return project_ap_url + path + image;
      } else {
        return project_ap_url + path + type + image;
      }
    }
  }
}
export function fileExtension(filename) {
  return filename.split(".").pop();
}
// export async function getData(method, parameters = null) {
//     let u;
//     parameters === null
//         ? (u = project_ap_url + method)
//         : (u = project_ap_url + method + "/" + parameters);

//     const token = localStorage.getItem("token");
//     let result;

//     const response = await fetch(u, {
//         method: "get", // or 'PUT'
//         mode: 'no-cors',
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: token,
//         },
//     });

//     const data = await response.json();
//     return data;
// }
export async function getData(method, parameters = null) {
  let u;
  parameters === null
    ? (u = project_ap_url + method)
    : (u = project_ap_url + method + "/" + parameters);

  const token = localStorage.getItem("token");
  let result;
  const response = await fetch(
    u,
    { mode: "cors" },
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );

  const data = await response.json();
  return data;
}
export async function getApiData(method, parameters = null) {
  let u;
  parameters === null
    ? (u = project_ap_url + method)
    : (u = project_ap_url + method + "/" + parameters);

  const token = localStorage.getItem("authToken");
  const response = await fetch(u, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  const data = await response.json();
  return data;
}
export function doParseHTML(string) {
  return parse(string);
}
export function validateField(
  fieldName,
  value,
  pattern = "",
  pattern_msg = "",
  length = ""
) {
  let msg = "";
  if ((fieldName && value.length <= 0) || value === "" || value === null) {
    msg = fieldName + " is required";
  } else if (value.length < length) {
    msg = fieldName + " is too short";
  } else if (pattern != "" && pattern != undefined && pattern != null) {
    if (checkPattern(value, pattern) == true) {
      if (
        pattern_msg != "" &&
        pattern_msg != null &&
        pattern_msg != undefined
      ) {
        msg = pattern_msg;
      } else {
        msg = fieldName + " is not valid";
      }
    }
  }
  return msg;
}
export function toSluUrl(str) {
  return str.replace(/\s+/g, "-").toLowerCase();
}
export function deleteFromObject(keyToDelete, obj) {
  var l = keyToDelete.length;
  for (var key in obj) {
    if (key.substr(0, l) == keyToDelete) {
      // key begins with the keyToDelete
      delete obj[key];
    }
  }
  return obj;
}
export function isCheckedFeature(features, input_arr, feature_name = "den") {
  let found = false;
  if (features?.length > 0 && input_arr?.length > 0) {
    let newInputArr = [];
    input_arr?.map((feature, index) => {
      newInputArr.push(parseInt(feature));
    });
    features?.map((item, index) => {
      if (newInputArr.includes(parseInt(item?.id))) {
        let title = item?.title;
        if (title.toLowerCase() === feature_name) {
          found = true;
        } else if (title.toLowerCase() === feature_name) {
          found = true;
        }
      }
    });
  }
  return found;
}
