import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PayPal from "../../../common/paypal";
import SaleListingStep1 from "../sale-listing-steps/step1";
import SaleListingStep2 from "../sale-listing-steps/step2";
import SaleListingStep3 from "../sale-listing-steps/step3";
import { postData } from "../../../../helpers/api";

export default function SaleListingForm({
  formStep,
  register,
  errors,
  changeStep,
  watchAllFields,
  isValid,
  control,
  choosePaymentMethod,
  paymentMethod,
  getPaypalObject,
  errorPaypalOrder,
  cancelPaypalOrder,
  checkoutError,
  handleCardDetailsChange,
  handleSubmit,
  setCheckoutError,
  stripePayment,
  property_slug,
  isFormProcessing,
  trigger,
  calculateListingFee,
  siteSettings,
  calculateListingProcessingFee,
}) {
  const [isProcessing, setProcessingTo] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const chargePayment = async (
    clientSecret,
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodSetup,
      setup_future_usage: "off_session",
    });
    if (result.error) {
      setCheckoutError(result.error.message);
      setProcessingTo(false);
      return;
    } else if ((result.status = "succeeded")) {
      let data = watchAllFields;
      let newData = {
        ...data,
        payment_intent: result.paymentIntent.id,
        payment_method_id: paymentMethodReq.paymentMethod.id,
        customer_id: customer_id,
        property: property_slug,
        amount: calculateListingFee(),
      };
      stripePayment(newData);
      setProcessingTo(false);
    }
  };
  const onSubmit = async (data) => {
    setProcessingTo(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      } else {
        var form_data = new FormData();
        for (var key in watchAllFields) {
          form_data.append(key, watchAllFields[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("amount", calculateListingFee());
        await postData("api/create-payment-intent", form_data).then((data) => {
          let client_secret = data.arr.client_secret;
          let client_secret_setup = data.arr.setup_client_secret;
          if (data.status === 1) {
            let card_result = stripe.confirmCardSetup(client_secret_setup, {
              payment_method: {
                card: cardElement,
                // billing_details: billingDetails,
              },
            });

            if (card_result.error) {
              setCheckoutError(card_result.error.message);
              setProcessingTo(false);
              return;
            } else {
              let paymentMethod = "";
              let setup_intent_id = "";
              card_result.then((response) => {
                if (response.error) {
                  setCheckoutError(response.error.message);
                  setProcessingTo(false);
                  return;
                } else {
                  paymentMethod = response.setupIntent.payment_method;
                  setup_intent_id = response.setupIntent.id;
                  chargePayment(
                    client_secret,
                    paymentMethodReq,
                    setup_intent_id,
                    paymentMethod,
                    data.arr.customer
                  );
                }
              });
            }
          }
        });
      }
    } catch (err) {
      setCheckoutError(err.message);
    }
    // let newData = { ...data, paymentMethod: paymentMethod, imageThumbnail: imageThumbnail };
    //
    // dispatch(postProperty(newData, property_id))
  };

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {formStep === 0 ? (
          <SaleListingStep1
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
          />
        ) : formStep === 1 ? (
          <SaleListingStep2
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
          />
        ) : formStep === 2 ? (
          <SaleListingStep3
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
            siteSettings={siteSettings}
            calculateListingProcessingFee={calculateListingProcessingFee}
          />
        ) : formStep === 3 ? (
          <fieldset>
            <div className="form_row row justify_center_flex chk">
              <div className="col-xs-9">
                <div className="creditCard">
                  <div className="flex flex-2 headCredit cardSecBar">
                    <div className="inner">
                      <div className="lblBtn text-left">
                        <input
                          type="radio"
                          id="card1"
                          tabindex="1"
                          name="card"
                          checked={
                            paymentMethod === "credit-card" ? true : false
                          }
                          onChange={() => choosePaymentMethod("credit-card")}
                        />
                        <label htmlFor="card1">Credit Card</label>
                      </div>
                    </div>
                    <div className="inner">
                      <ul className="text-right">
                        <li>
                          <img src="/images/dashboard/card1.svg" alt="" />
                        </li>
                        <li>
                          <img src="/images/dashboard/card2.svg" alt="" />
                        </li>
                        <li>
                          <img src="/images/dashboard/card3.svg" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  {paymentMethod === "credit-card" ? (
                    <div className="flex flex-2 cardSec">
                      <div className="inner">
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: "14px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                          onChange={handleCardDetailsChange}
                        />
                        {checkoutError && (
                          <div className="alert alert-danger">
                            {checkoutError}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex flex-2 headCredit paypalSecBar">
                    <div className="inner">
                      <div className="lblBtn text-left">
                        <input
                          type="radio"
                          id="card2"
                          tabindex="1"
                          name="card"
                          onChange={() => choosePaymentMethod("paypal")}
                          checked={paymentMethod === "paypal" ? true : false}
                        />
                        <label htmlFor="card2">Pay Pal</label>
                      </div>
                    </div>
                    <div className="inner other_pay">
                      <img src="/images/dashboard/paypal.svg" alt="" />
                    </div>
                  </div>
                  {paymentMethod === "paypal" ? (
                    <PayPal
                      price={calculateListingFee()}
                      getPaypalObject={getPaypalObject}
                      cancelPaypalOrder={cancelPaypalOrder}
                      errorPaypalOrder={errorPaypalOrder}
                      priceMonth={watchAllFields.expires_in}
                      description="Sale Listing Creation"
                    />
                  ) : (
                    ""
                  )}
                  {/* <div className="flex flex-2 headCredit paylaterSecBar">
                                                                    <div className="inner">
                                                                        <div className="lblBtn text-left">
                                                                            <input type="radio" id="card3" tabindex="1" name="card" />
                                                                            <label htmlFor="card3">Pay Later</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="inner other_pay_more">
                                                                        <img src="/images/dashboard/pay-later.svg" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="paylaterSec">
                                                                    <div className="pay_after_btns flex">
                                                                        <a href="?" className="webBtn pay_later_btn">
                                                                            <img src="/images/dashboard/pay-later.svg" alt="" />
                                                                            <span>Pay Later</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="text-center small_card_txt">
                                                                        <p><small>After clicking "Pay Later", you will be redirected to PayLater to complete your listing securely.</small></p>
                                                                    </div>
                                                                </div> */}
                </div>
              </div>
            </div>
            <div className="cmnBtn text-center">
              <button
                type="button"
                className="webBtn backBtn"
                onClick={() => changeStep(formStep - 1)}
              >
                Previous
              </button>

              {paymentMethod === "credit-card" ? (
                <button
                  type="submit"
                  className="webBtn colorBtn"
                  disabled={isProcessing || isFormProcessing ? "disabled" : ""}
                >
                  Submit
                  {isProcessing || isFormProcessing ? (
                    <i className="spinner"></i>
                  ) : (
                    ""
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          </fieldset>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
