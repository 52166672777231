import React, { useState, useEffect } from "react";
import {
  getBranchFloorPlans,
  checkPropertyAddressAvailability,
  postData,
  getServerImage,
  fileValidation,
  upload_file,
  imageValidation,
} from "../../../../helpers/api";
import PropertyStep1 from "./property/step1";
import PropertyStep2 from "./property/step2";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";

export default function AddPropertyForm({
  changeStep,
  step,
  branches,
  floor_plans,
  property_row,
  countries,
  states,
  handleProperty,
  isFormProcessing,
  showPopup,
  closePopup,
  addressLoading,
  popup,
}) {
  useEffect(() => {
    if (
      property_row !== undefined &&
      property_row !== null &&
      property_row !== ""
    ) {
      changeStep(1);
    }
  }, [property_row]);
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const onSubmit = (data) => {
    let newData = "";
    if (
      addressAvailable?.address !== "" &&
      addressAvailable?.address !== null &&
      addressAvailable?.latitude !== "" &&
      addressAvailable?.latitude !== null &&
      addressAvailable?.longitude !== "" &&
      addressAvailable?.longitude !== null &&
      addressAvailable?.place_id !== "" &&
      addressAvailable?.place_id !== null &&
      addressAvailable?.place_id !== undefined &&
      addressAvailable?.latitude !== undefined &&
      addressAvailable?.longitude !== undefined &&
      addressAvailable?.address !== undefined
    ) {
      newData = {
        ...data,
        formatted_address: addressAvailable?.address,
        latitude: addressAvailable?.latitude,
        longitude: addressAvailable?.longitude,
        place_id: addressAvailable?.place_id,
        property_type: property.type,
      };
    } else {
      newData = {
        ...data,
        latitude: "",
        longitude: "",
        place_id: "",
        formatted_address: "",
        property_type: property.type,
      };
    }
    handleProperty(newData);
  };
  const [property, setProperty] = useState({
    type: "single",
  });
  const [verifyFormLoading, setVerifyFormLoading] = useState(false);
  const [floorPlans, setFloorPlans] = useState(null);
  const [addressAvailable, setAddressAvailability] = useState(null);
  const handleTypeChange = (type) => {
    setProperty({ ...property, type: type });
  };
  useEffect(() => {
    setFloorPlans(floor_plans);
    // setAddressAvailability({ status: 1, msg: "Address is available" })
  }, [floor_plans]);
  async function handleBranchChange(value) {
    let floor_plans = await getBranchFloorPlans(value);
    setFloorPlans(floor_plans.floor_plans);
  }
  async function checkAddressAvailability(data) {
    // ;
    addressLoading = true;
    setAddressAvailability({ loading: true, address: null });
    let addressAvailableApi = await checkPropertyAddressAvailability(data);
    if (addressAvailableApi?.status === 1) {
      setAddressAvailability({
        ...addressAvailable,
        address: addressAvailableApi?.address,
        latitude: addressAvailableApi?.latitude,
        longitude: addressAvailableApi?.longitude,
        place_id: addressAvailableApi?.place_id,
        status: addressAvailableApi?.status,
        msg: addressAvailableApi?.msg,
        loading: false,
      });

      addressLoading = false;
    } else {
      if (addressAvailableApi?.user_property === 1) {
        toast.error(addressAvailableApi?.msg, TOAST_SETTINGS);
      }
      setAddressAvailability({
        ...addressAvailable,
        status: addressAvailableApi?.status,
        msg: addressAvailableApi?.msg,
        property_slug: addressAvailableApi?.property_slug,
        loading: false,
      });
      addressLoading = false;
    }

    if (addressAvailableApi?.status_exist === 1) {
      showPopup(true);
    }
  }
  useEffect(() => {}, [addressAvailable]);
  const [verifyAddressFile, setVerifyAddressFile] = useState({
    file: null,
    file_name: null,
    loading: false,
  });
  useEffect(() => {
    setValue("branch", property_row?.branch);
    setValue("floor_plan", property_row?.floor_plan);
    setValue("state", property_row?.state);
    setValue("address_line1", property_row?.address_line1);
    setValue(
      "address_line2",
      property_row?.address_line2 !== null && property_row?.address_line2 !== ""
        ? property_row?.address_line2
        : ""
    );
    setValue("city", property_row?.city);
    setValue("zip_code", property_row?.zip_code);
  }, [property_row]);
  async function sendPropertyRequest(e) {
    e.preventDefault();
    if (
      addressAvailable?.property_slug !== undefined &&
      addressAvailable?.property_slug !== null &&
      addressAvailable?.property_slug !== ""
    ) {
      if (
        verifyAddressFile?.file !== null &&
        verifyAddressFile?.file_name !== null
      ) {
        setVerifyFormLoading(true);
        let form_data = {
          file: verifyAddressFile?.file,
          file_name: verifyAddressFile?.file_name,
        };

        postData(
          "api/send-property-verify-address-request/" +
            addressAvailable?.property_slug,
          form_data
        ).then((data) => {
          setVerifyFormLoading(false);

          if (data.status === 1) {
            // toast.success(data.msg,
            //     TOAST_SETTINGS
            // );
            setTimeout(() => {
              window.location.replace("/properties");
            }, 1000);
          } else {
            toast.error(data.msg, TOAST_SETTINGS);
          }
        });
      } else {
        toast.error("Please upload a document first!", TOAST_SETTINGS);
      }
    } else {
      toast.error("Please Check address availability first", TOAST_SETTINGS);
    }
  }
  let logo = null;
  function uploadLogo() {
    logo.click();
  }
  async function handleUpload(e) {
    setVerifyAddressFile({ ...verifyAddressFile, loading: true });
    const files = e.target.files[0];
    let valid = imageValidation(files);
    if (valid.error) {
      toast.error(valid.error, TOAST_SETTINGS);
    } else {
      let file = await upload_file(files);
      if (file?.status === 1) {
        document.getElementById("verify_document").value = "";
        setVerifyAddressFile({
          ...verifyAddressFile,
          file: file?.file_name,
          file_name: files?.name,
          loading: false,
        });
      } else {
        setVerifyAddressFile({ ...verifyAddressFile, loading: false });
        toast.error(file?.msg, TOAST_SETTINGS);
      }
    }
  }
  return (
    <>
      <ToastContainer />
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          <PropertyStep1
            property_type={property.type}
            handleTypeChange={handleTypeChange}
            changeStep={changeStep}
            step={step}
            property_row={property_row}
          />
        ) : step === 1 ? (
          <PropertyStep2
            step={step}
            changeStep={changeStep}
            branches={branches}
            floorPlans={floorPlans}
            property_row={property_row}
            handleBranchChange={handleBranchChange}
            countries={countries}
            states={states}
            register={register}
            control={control}
            isValid={isValid}
            errors={errors}
            isFormProcessing={isFormProcessing}
            watchAllFields={watchAllFields}
            checkAddressAvailability={checkAddressAvailability}
            addressAvailable={addressAvailable}
            addressLoading={addressLoading}
            showPopup={showPopup}
            closePopup={closePopup}
            trigger={trigger}
          />
        ) : (
          ""
        )}
      </form>
      {popup === true ? (
        <section
          className="popup"
          data-popup="address-not-match"
          style={{ display: "block" }}
        >
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div className="crosBtn" onClick={() => closePopup()}></div>
                <h4>Verify Address</h4>
                <hr />
                <p>
                  This property is currently owned by another user in Loftus. We
                  will send the current owner of this property a notification
                  informing them that another user is claiming to be the owner
                  of this property. If the current owner verifies that this
                  property is no longer theirs, or if they do not have
                  reoccurring transactions and they have not responded within 7
                  calendar days, then you will be granted permission to create
                  this property on Loftus. If you would like a faster response,
                  please attach the deed of this property below. One of our
                  agents will review and provide feedback as quickly as
                  possible. We thank you for your cooperation and apologize for
                  any inconvenience this may have caused.{" "}
                </p>
                <form action="" method="POST" onSubmit={sendPropertyRequest}>
                  {verifyAddressFile?.loading === true ? (
                    <div className="loading_bar">
                      <img src="/images/loading-bar.gif" />
                    </div>
                  ) : (
                    ""
                  )}
                  {verifyAddressFile?.file !== null &&
                  verifyAddressFile?.loading !== true ? (
                    <div className="flex file_preview">
                      <div className="sm_icon">
                        <img src="/images/document.png" alt="" />
                      </div>
                      <div className="_cnt">
                        <h5>{verifyAddressFile?.file_name}</h5>
                        <a
                          href={getServerImage(
                            "storage/attachments/",
                            verifyAddressFile?.file
                          )}
                          target="_blank"
                        >
                          <i className="fi-eye"></i> View File
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="image_upload_blk uploadImg default_btn_prop"
                    data-upload="gallery_image"
                    onClick={uploadLogo}
                    disabled={
                      verifyAddressFile?.loading === true ? true : false
                    }
                  >
                    <div className="small_upload_icon">
                      <img src="/images/dashboard/icons/upload.png" alt="" />
                    </div>
                    <h3>
                      Upload{" "}
                      {verifyAddressFile?.loading === true ? (
                        <i className="spinner"></i>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                  <input
                    type="file"
                    name="verify_document"
                    id="verify_document"
                    className="uploadFile"
                    onChange={handleUpload}
                    ref={(file) => (logo = file)}
                  />
                  <hr />
                  <div className="bTn justify_btn_center">
                    <button
                      type="button"
                      className="webBtn"
                      onClick={() => closePopup()}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="webBtn colorBtn"
                      disabled={
                        verifyAddressFile?.loading === true ||
                        verifyFormLoading === true
                          ? true
                          : false
                      }
                    >
                      Send{" "}
                      {verifyAddressFile?.loading === true ||
                      verifyFormLoading === true ? (
                        <i className="spinner"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
