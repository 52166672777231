import React from 'react'
import { NavLink } from 'react-router-dom';
import { getServerImage } from '../../helpers/api';
export default function UserCard({ member }) {
    return (
        <>
            <div className="dashboard_user_card">
                <div className="dash_user_dp">
                    <img src={getServerImage('storage/members/', member.mem_image)} alt={member.mem_type === 'company' ? member.mem_fullname : member.mem_fname + " " + member.mem_lname} />
                </div>
                <div className="dash_user_title">
                    <h4>Welcome!</h4>
                    <p>{member.mem_type === 'company' ? member.mem_fullname : member.mem_fname + " " + member.mem_lname}</p>
                    <h6>{member.mem_type === 'company' ? member.mem_business : member.mem_bio}</h6>
                    <div className="bTn">
                        <NavLink to="/profile-settings" className="webBtn">Edit Profile</NavLink>
                    </div>
                </div>
                
            </div>
        </>
    )
}
