import {
    FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT,
    FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_SUCCESS,
    FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_FAILED,
    SINGLE_MAINTENANCE_REQUESTS_MESSAGE,
    SINGLE_MAINTENANCE_REQUESTS_MESSAGE_SUCCESS,
    SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED,
    FETCH_MAINTENANCE_REQUESTS_CONTENT,
    FETCH_MAINTENANCE_REQUESTS_CONTENT_FAILED,
    FETCH_MAINTENANCE_REQUESTS_CONTENT_SUCCESS,
    MAINTENANCE_REQUESTS_DELETE__MESSAGE,
    MAINTENANCE_REQUESTS_DELETE_MESSAGE_SUCCESS,
    MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED,
    GET_FILTER_MAINTENANCE_REQUESTS,
    GET_FILTER_MAINTENANCE_REQUESTS_FAILED,
    GET_FILTER_MAINTENANCE_REQUESTS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false,
    isFilterLoading: false,
    received_maintenance_arr: [],
    maintenance_arr: [],
    assigned_requests_arr: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_FILTER_MAINTENANCE_REQUESTS:
            return {
                ...state,
                isFilterLoading: true,
            };
        case GET_FILTER_MAINTENANCE_REQUESTS_SUCCESS:
            return {
                ...state,
                isFilterLoading: false,
                received_maintenance_arr: payload?.received_maintenance_arr,
                maintenance_arr: payload?.maintenance_arr,
                assigned_requests_arr: payload?.assigned_requests_arr,
            };

        case GET_FILTER_MAINTENANCE_REQUESTS_FAILED:
            return {
                ...state,
                isFilterLoading: false,
                error: payload,
            };
        case FETCH_MAINTENANCE_REQUESTS_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_MAINTENANCE_REQUESTS_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
                received_maintenance_arr: payload?.received_maintenance_arr,
                maintenance_arr: payload?.maintenance_arr,
                assigned_requests_arr: payload?.assigned_requests_arr,
            };
        case FETCH_MAINTENANCE_REQUESTS_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case SINGLE_MAINTENANCE_REQUESTS_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SINGLE_MAINTENANCE_REQUESTS_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
            };
        case SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload,
            };
        case MAINTENANCE_REQUESTS_DELETE__MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case MAINTENANCE_REQUESTS_DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
            };
        case MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload,
            };
        default:
            return state;
    }
}
