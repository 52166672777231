import React from "react";
import { format_date, getServerImage } from "../../../../helpers/api";
import { NavLink } from "react-router-dom";

export default function MaintenanceRequestBlk({ items, type = "" }) {
  return (
    <>
      {items?.length > 0 ? (
        items?.map((item, index) => {
          return (
            <div className="lst" data-offer="received-request" key={index}>
              {type !== "" ? (
                <NavLink
                  to={
                    "/view-maintenance-request/" + item?.encoded_id + "/" + type
                  }
                  className="overlay_link_dt"
                ></NavLink>
              ) : (
                <NavLink
                  to={
                    "/view-maintenance-request/" +
                    item?.encoded_id +
                    "/" +
                    item?.type
                  }
                  className="overlay_link_dt"
                ></NavLink>
              )}

              <ul>
                <li>
                  <div className="icoBlk">
                    <div className="icon border_solid wide_mobile_img">
                      <img
                        src={item?.property_thumbnail}
                        alt={item?.property_name}
                      />
                      {item?.assigned_staff_members?.length > 0 &&
                      item?.status !== "completed" &&
                      item?.status !== "finished" ? (
                        <span className="badge blue cell_lbl">In Progress</span>
                      ) : item?.status === "pending" ? (
                        <span className="badge yellow cell_lbl">Pending</span>
                      ) : item?.status === "finished" ? (
                        <span className="badge blue cell_lbl">Finished</span>
                      ) : item?.status === "assigned" ? (
                        <span className="badge blue cell_lbl">In Progress</span>
                      ) : (
                        <span className="badge green cell_lbl">Completed</span>
                      )}
                    </div>
                    <div className="txt">
                      <h5>{item?.property_title}</h5>
                      <p>
                        <small>{item?.property_address}</small>
                      </p>
                      {item?.assigned_staff_members?.length > 0 &&
                      item?.status !== "completed" &&
                      item?.status !== "finished" ? (
                        <span className="badge blue hide_cell_hyp">
                          In Progress
                        </span>
                      ) : item?.status === "pending" ? (
                        <span className="badge yellow hide_cell_hyp">
                          Pending
                        </span>
                      ) : item?.status === "finished" ? (
                        <span className="badge blue hide_cell_hyp">
                          Finished
                        </span>
                      ) : item?.status === "assigned" ? (
                        <span className="badge blue hide_cell_hyp">
                          In Progress
                        </span>
                      ) : (
                        <span className="badge green hide_cell_hyp">
                          Completed
                        </span>
                      )}
                      <div className="hide_desktop_col">
                        <div className="sep_hypn">
                          <span>{item?.purpose}</span>
                        </div>
                        <div className="sep_hypn">
                          <span>{format_date(item?.created_at)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="hide_cell_hyp">
                  <h5 className="lease_heading_mini">Purpose</h5>
                  <div>{item?.purpose}</div>
                </li>
                <li className="hide_cell_hyp assigned_staff_z_index">
                  <ul className="tenants_dps_flex">
                    <h5 className="lease_heading_mini">Tenant</h5>
                    <li>
                      <div className="inner">
                        <div className="mini_icon">
                          <img
                            src={getServerImage(
                              "storage/members/",
                              item?.tenant_image
                            )}
                            alt={item?.tenant_name}
                          />
                        </div>
                        <div className="bio_info">
                          <div className="name">
                            <h4>{item?.tenant_name}</h4>
                          </div>
                          {/* <div className="address">
                            <small>
                            {item?.property_address}
                            </small>
                          </div> */}
                        </div>
                      </div>
                      <div className="ico">
                        <img
                          src={getServerImage(
                            "storage/members/",
                            item?.tenant_image
                          )}
                          alt={item?.tenant_name}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="hide_cell_hyp assigned_staff_z_index">
                  <ul className="tenants_dps_flex">
                    <h5 className="lease_heading_mini">Assigned to</h5>
                    {item?.assigned_staff_members?.length > 0
                      ? item?.assigned_staff_members?.map(
                          (assigned_staff_member, index) => {
                            return (
                              <li key={index}>
                                <div className="inner">
                                  <div className="mini_icon">
                                    <img
                                      src={assigned_staff_member?.thumb}
                                      alt={assigned_staff_member?.name}
                                    />
                                  </div>

                                  <div className="bio_info">
                                    <div className="name">
                                      <h4>{assigned_staff_member?.name}</h4>
                                    </div>
                                    {/* <div className="address">
                                      <small>
                                        4600 Mooretown Rd,
                                        <br /> Williamsburg VA 23185, USA
                                      </small>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="ico">
                                  <img
                                    src={assigned_staff_member?.thumb}
                                    alt={assigned_staff_member?.name}
                                  />
                                </div>
                              </li>
                            );
                          }
                        )
                      : "Not assigned yet!"}
                  </ul>
                </li>
                <li className="hide_cell_hyp">
                  <h5 className="lease_heading_mini">Opened</h5>
                  <div>{format_date(item?.created_at)}</div>
                </li>

                <li className="hide_cell_hyp">
                  <h5 className="lease_heading_mini">Status</h5>
                  <span className="badge green">In Progress</span>
                </li>
              </ul>
            </div>
          );
        })
      ) : (
        <div className="alert alert-danger">No item(s) found yet!</div>
      )}
    </>
  );
}
