import React, { useState, useEffect } from "react";

import LogLst from "./logLst";
import ExtensionLst from "./extensionLst";
import ShiftingsLst from "./shiftingsLst";
import { format_amount, format_date } from "../../../../helpers/api";

export default function ListingLogs({
  logs,
  type,
  extensions,
  shiftings,
  listing = false,
  owner = false,
  log_history,
}) {
  const [tabType, setTabType] = useState("logs");
  const changeType = (type) => {
    setTabType(type);
  };
  useEffect(() => {
    if (
      logs !== undefined &&
      logs !== null &&
      logs !== "" &&
      logs?.length > 0
    ) {
      setTabType("logs");
    } else if (
      extensions !== undefined &&
      extensions !== null &&
      extensions !== "" &&
      extensions?.length > 0
    ) {
      setTabType("extensions");
    } else if (
      shiftings !== undefined &&
      shiftings !== null &&
      shiftings !== "" &&
      shiftings?.length > 0
    ) {
      setTabType("shiftings");
    }
  }, []);
  return (
    <>
      <h3 className="detail_heading">Listing History</h3>
      {/* <ul className="nav nav-tabs">
                {
                    owner === false && logs !== undefined && logs !== null && logs !== '' && logs?.length > 0 ?
                        <li className={tabType === 'logs' ? 'active' : ''}><a className="a" data-toggle="tab" href="javascript:void(0)" onClick={() => changeType('logs')}>
                            Logs <span>({logs?.length > 0 ? logs?.length : 0})</span></a>
                        </li>
                        :
                        ""
                }
                {
                    owner === false && extensions !== undefined && extensions !== null && extensions !== '' && extensions?.length > 0 ?
                        <li className={tabType === 'extensions' ? 'active' : ''}><a className="b" data-toggle="tab" href="javascript:void(0)" onClick={() => changeType('extensions')}>
                            Extensions <span>({extensions?.length > 0 ? extensions?.length : 0})</span></a>
                        </li>
                        :
                        ""
                }
                {

                    listing === false && shiftings !== undefined && shiftings !== null && shiftings !== '' && shiftings?.length > 0 ?

                        <li className={tabType === 'shiftings' ? 'active' : ''}><a className="b" data-toggle="tab" href="javascript:void(0)" onClick={() => changeType('shiftings')}>
                            Shiftings <span>({shiftings?.length > 0 ? shiftings?.length : 0})</span></a>
                        </li>
                        :
                        ""
                }
            </ul>
            <div className="outer_list hide_sm_clmn whole_lst_lnk logs_section search_expense_com">
                <div className="tab-content">
                    {
                        owner === false && logs !== undefined && logs !== null && logs !== '' && logs?.length > 0 ?

                            <div id="pending_leases" className={tabType === 'logs' ? "tab-pane fade a active in" : "tab-pane"}>
                                <div className="lst" data-offer="received-leases">
                                    {
                                        logs !== undefined && logs !== null && logs !== '' && logs?.length > 0 ?

                                            <LogLst logs={logs} type={type} />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            :
                            ""
                    }
                    {
                        owner === false && extensions !== undefined && extensions !== null && extensions !== '' && extensions?.length > 0 ?

                            <div id="pending_leases" className={tabType === 'extensions' ? "tab-pane fade a active in" : "tab-pane"}>
                                <div className="lst" data-offer="received-leases">
                                    {
                                        extensions !== undefined && extensions !== null && extensions !== '' && extensions?.length > 0 ?

                                            <ExtensionLst extensions={extensions} />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            :
                            ""
                    }
                    {
                        listing === false && shiftings !== undefined && shiftings !== null && shiftings !== '' && shiftings?.length > 0 ?

                            <div id="pending_leases" className={tabType === 'shiftings' ? "tab-pane fade a active in" : "tab-pane"}>
                                <div className="lst" data-offer="received-leases">
                                    {
                                        shiftings !== undefined && shiftings !== null && shiftings !== '' && shiftings?.length > 0 ?

                                            <ShiftingsLst shiftings={shiftings} />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
            </div> */}

      <div className="outer_list hide_sm_clmn whole_lst_lnk logs_section search_expense_com">
        <div class="tbl_new_style">
          <div class="balance_lst">
            <div>
              <h5>Date</h5>
            </div>
            <div>
              <h5>Status</h5>
            </div>
            <div>
              <h5>Price</h5>
            </div>
          </div>
          {log_history?.map((log, index) => {
            return (
              <div class="balance_lst" key={index}>
                <div>{format_date(log?.date)}</div>
                <div>{log?.description}</div>
                <div>
                  {format_amount(log?.price)}
                  {log?.price_upgraded === 1 ? (
                    <img src="/images/up_arrow_green.svg" alt="" />
                  ) : log?.price_upgraded === 0 ? (
                    <img src="/images/down_arrow_red.svg" alt="" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
          {/* // <div class="balance_lst">
                    //     <div>Jan 1, 2022</div>
                    //     <div>Price Change</div>
                    //     <div>$870,000 <img src="/images/up_arrow_green.svg" alt="" /></div>
                    // </div> */}
        </div>
      </div>
    </>
  );
}
