import React from 'react'
import { format_amount } from '../../../../helpers/api'

export default function SaleListingStep2({ step, changeStep, register, errors, control, watchAllFields, isValid }) {
    return (
        <>
            <fieldset>
                <div className="form_row row justify_center_flex">
                    <div className="col-xs-9">
                        <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
                            <li>
                                <strong>Price</strong>
                                <em>{format_amount(watchAllFields.price)}</em>
                            </li>

                            <li>
                                <strong>HOA Fee</strong>
                                <em>{format_amount(watchAllFields.hoa_fee)}</em>
                            </li>
                            <li>
                                <strong>HOA Fee Frequency</strong>
                                <em>{watchAllFields.hoa_fee_freq}</em>
                            </li>
                            <li>
                                <strong>Condo Fee</strong>
                                <em>{format_amount(watchAllFields.condo_fee)}</em>
                            </li>
                            <li>
                                <strong>Condo Fee Frequency</strong>
                                <em>{watchAllFields.condo_fee_freq}</em>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="cmnBtn text-center">
                    <button type="button" className="webBtn backBtn" onClick={() => changeStep(step - 1)}>Previous</button>
                    <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)}>Next</button>
                </div>
            </fieldset>
        </>
    )
}
