import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MaintenanceForm from "./form";
import { Link } from "react-router-dom";
import DetailPopup from "../maintenance_profile/detail-popup";
import { format_amount } from "../../../../helpers/api";

export default function MaintenanceBlk({ items, openDetailPopup }) {
  const [isMoreHovered, setIsMoreHovered] = useState(false);
  const [viewTeamMember, setViewTeamMember] = useState(null);
  const [staff, setStaff] = useState(false);
  return (
    <>
      {items?.length > 0 ? (
        items?.map((item, index) => {
          return (
            <div className="col" key={index}>
              <div className="inner">
                <div
                  className="popup_maintenance_new"
                  onClick={() => openDetailPopup(item)}
                ></div>
                <Link
                  href=""
                  className="colorBtn webBtn mini_web_btn new_lnk_card_lnk"
                  type="button"
                >
                  <img src="/images/chat-icon.svg" alt="" />
                </Link>
                <div className="b_dy new_responsive_b_dy">
                  <div className="user_info">
                    <div className="small_image">
                      <img src={item?.image} alt={item?.name} />
                    </div>
                    <h5>{item?.name ? item?.name : item?.email}</h5>
                  </div>
                  <div className="b_dyinner">
                    <div className="rating_rate cmn_gap_b">
                      <div className="review_star">
                        <i className="fa fa-star"></i>
                        <span>
                          {" "}
                          {item?.total_ratings ? item?.total_ratings : 0} ({" "}
                          {item?.reviews?.length} Reviews)
                        </span>
                      </div>
                      {parseInt(item?.maintenance_profile) === 0 ? (
                        <div className="rate_rate">
                          {format_amount(item?.hourly_rate)}
                          /hr
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {parseInt(item?.maintenance_profile) === 0 ? (
                      <div className="cat_new_ins">
                        <p>No service profile yet!</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {parseInt(item?.new_user) === 1 ? (
                      <div className="cat_new_ins">
                        <p>No account found yet!</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.trade_categories_arr?.length > 0 ? (
                      <div className="cat_new_ins">
                        {item?.trade_categories_arr
                          ?.slice(0, 5)
                          .map((trade_category, index) => {
                            return (
                              <div key={index} data-skill={trade_category}>
                                <img
                                  src={
                                    trade_category?.toLowerCase() ===
                                    "pest-control"
                                      ? "/images/" +
                                        trade_category?.toLowerCase() +
                                        ".png"
                                      : "/images/" +
                                        trade_category?.toLowerCase() +
                                        ".svg"
                                  }
                                  alt={trade_category}
                                />
                              </div>
                            );
                          })}
                        {item?.trade_categories_arr?.length > 5 ? (
                          <span
                            className="more_service more_more"
                            onMouseEnter={() => setIsMoreHovered(true)}
                            onMouseLeave={() => setIsMoreHovered(false)}
                          >
                            +{item?.trade_categories_arr?.length - 5}
                            <div className="after_hover_blk">
                              {item?.trade_categories_arr?.map(
                                (trade_category, index) => {
                                  return (
                                    <div
                                      key={index}
                                      data-skill={trade_category}
                                      className="service_icon_hover"
                                    >
                                      <img
                                        src={
                                          trade_category?.toLowerCase() ===
                                          "pest-control"
                                            ? "/images/" +
                                              trade_category?.toLowerCase() +
                                              ".png"
                                            : "/images/" +
                                              trade_category?.toLowerCase() +
                                              ".svg"
                                        }
                                        alt={trade_category}
                                      />
                                      <span>{trade_category}</span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.req_status === "pending" ? (
                      <div className="badge yellow_badge">Pending</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {item?.properties?.length > 0 ? (
                  <div
                    className={
                      isMoreHovered
                        ? "property_hover_show more_no"
                        : "property_hover_show"
                    }
                  >
                    <ul>
                      {item?.properties?.map((property, index) => {
                        return <li key={index}>{property?.title}</li>;
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="alert alert-danger">No staff added yet!</div>
      )}

      {viewTeamMember !== null && viewTeamMember?.id > 0 ? (
        <section className="popup" data-popup="create-new-staff">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div
                  className="crosBtn"
                  onClick={() => setViewTeamMember(null)}
                ></div>
                <h3>Staff Details</h3>
                <div className="maintenance_detail_flex flex">
                  <div className="colL">
                    <div className="inner">
                      <div className="image">
                        <img
                          src={viewTeamMember?.image}
                          alt={viewTeamMember?.name}
                        />
                      </div>
                      <div className="txt">
                        <h5>{viewTeamMember?.name}</h5>
                        <p>{viewTeamMember?.address}</p>
                        {viewTeamMember?.phone ? (
                          <a href={"tel: " + viewTeamMember?.phone}>
                            <i className="fa fa-phone"></i>
                            <span>{viewTeamMember?.phone}</span>
                          </a>
                        ) : (
                          ""
                        )}
                        <a href={"mailto: " + viewTeamMember?.email}>
                          <i className="fa fa-envelope"></i>
                          <span>{viewTeamMember?.email}</span>
                        </a>
                        {viewTeamMember?.trade_categories_arr?.length > 0 ? (
                          <>
                            {/* <h6>Categories</h6> */}
                            <div className="cat_new_ins mt-4">
                              {viewTeamMember?.trade_categories_arr?.map(
                                (trade_category, index) => {
                                  return (
                                    <div
                                      key={index}
                                      data-skill={trade_category}
                                    >
                                      <img
                                        src={
                                          "/images/" +
                                          trade_category?.toLowerCase() +
                                          ".svg"
                                        }
                                        alt={trade_category}
                                      />
                                      {/* <span>{trade_category}</span> */}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="colR">
                    <div className="inner">
                      <h4>Assigned Properties</h4>
                      <ul>
                        {viewTeamMember?.properties?.map(
                          (property, p_index) => {
                            return (
                              <li key={p_index}>
                                <NavLink
                                  to={"/user-property/" + property?.slug}
                                >
                                  {property?.title}
                                </NavLink>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
