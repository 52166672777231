import React, { useState } from 'react'
import PropertyBlk from '../../common/propertyBlk';


export default function ExploreGrid(props) {
    const { data, listView, isLoading, isMarkerHovered, handleMarkerHover, handleMarkerLeave, dragLoading, properties } = props;
    const [visibleProperties, setVisibleProperties] = useState({
        total: 10,
        isFetching: false
    });
    const loadMoreItems = () => {
        setVisibleProperties({ ...visibleProperties, isFetching: true })
        setTimeout(() => {
            setVisibleProperties({ ...visibleProperties, total: parseInt(visibleProperties?.total) + 10, isFetching: false });
        }, 2000);
    };
    return (
        <>
            <div className={data?.length > 0 ? "colL" : "colL dataNotFound"} id="propertiesSection">

                {
                    isLoading || dragLoading ?
                        <div id="leadingMainContainer">
                            <div className="loading-container">
                                <div className="loading"></div>
                                <div id="loading-text">loading</div>
                            </div>
                        </div>
                        :
                        <div className={((listView == "list") ? "flex propert_list list_view" : "flex propert_list grid_view")}>
                            {
                                properties?.length > 0 ?
                                    data?.slice(0, visibleProperties?.total)?.map((val, index) => {
                                        return (
                                            <PropertyBlk data={val} key={index} isMarkerHovered={isMarkerHovered} handleMarkerHover={handleMarkerHover} handleMarkerLeave={handleMarkerLeave} className="search-explore-grid col" isExplore={true} branch_id={parseInt(val?.branch_id) > 0 ? parseInt(val?.branch_id) : false} />
                                        )
                                    })
                                    :
                                    <div className="notText">
                                        <img src="/images/not-found.png" />
                                        <h4>No results found!</h4>
                                    </div>
                            }

                        </div>
                }
                {
                    data?.length > 0 && visibleProperties?.total < data?.length ?
                        <div className="bTn text-center loadMoreBtn">
                            {
                                visibleProperties?.isFetching ?
                                    <div className="main-loading">
                                        <div className="loading-container">
                                            <div className="loading"></div>
                                            <div id="loading-text">loading</div>
                                        </div>
                                    </div>
                                    :
                                    <button onClick={loadMoreItems} className='webBtn'>Load More</button>
                            }
                        </div>
                        :
                        ""
                }
            </div>
        </>
    )
}
