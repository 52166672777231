import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useParams, useLocation } from "react-router-dom";
import {
  fetchCommercialFrontendProperty,
  fetchBranchListings,
} from "../../states/actions/fetchCommercialFrontendProperty";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import PropertyHeader from "./single-property/section/header";
import { comercialSearchParams, postData, projectUrl } from "../../helpers/api";
import PropertyGallery from "./single-property/section/gallery";
import PropertyDetails from "./single-property/section/details";
import CommercialFilter from "./single-property/section/commercial-filter";
import PropertyListings from "./single-property/section/listings";
import HouseOperations from "./single-property/section/house-operations";
import PropertyReviews from "./single-property/section/reviews";
import PropertyLocation from "./single-property/section/locations";
export default function SingleCommercialProperty() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const commercialGetParams = comercialSearchParams(searchParams);
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.fetchCommercialFrontendProperty.content
  );
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector(
    (state) => state.fetchCommercialFrontendProperty.isLoading
  );
  const isSearchLoading = useSelector(
    (state) => state.fetchCommercialFrontendProperty.isSearchLoading
  );
  const searchedListings = useSelector(
    (state) => state.fetchCommercialFrontendProperty.listings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const searchListings = (frmData) => {
    dispatch(fetchBranchListings(slug, frmData));
  };
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const [reviewPopup, setReviewPopup] = useState({
    item: null,
    show: false,
  });

  const showReviewPopup = (item) => {
    setReviewPopup({ ...reviewPopup, item: item, show: true });
    // alert(!popup);
  };

  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };

  const property_url = projectUrl("property/" + slug);
  useEffect(() => {
    dispatch(fetchCommercialFrontendProperty(slug, commercialGetParams));
  }, []);
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let branch_row = data.branch_row;
  let media = data?.branch_row?.media;
  let member = data?.member;
  let features = data?.features;
  let amenities = data?.amenities;
  let walkscore = data?.branch_row?.walkscore;
  let branch_contact_row = data?.branch_row?.branch_contact_row;
  return (
    <>
      <div
        index=""
        className={
          popup.show === true &&
          popup.item != undefined &&
          popup.item != null &&
          popup.item != ""
            ? "floor_popup"
            : ""
        }
      >
        <section className="property_detail_main_sr">
          <div className="contain">
            <PropertyHeader
              address={branch_row?.formatted_address}
              floor_plan_name={branch_row?.name}
              item={branch_row}
              member={member}
              property_url={property_url}
              property={branch_row}
            />
            <div className="search-detail-grid">
              <PropertyGallery
                images={
                  media !== undefined &&
                  media !== null &&
                  media !== "" &&
                  media?.media_images !== undefined &&
                  media?.media_images !== null &&
                  media?.media_images !== "" &&
                  media?.media_images?.length > 0
                    ? media?.media_images
                    : []
                }
                popup={popup}
                closePopup={closePopup}
                showPopup={showPopup}
                property={branch_row}
                thumbnail={
                  media !== undefined &&
                  media !== null &&
                  media !== "" &&
                  media?.imageThumbnail !== undefined &&
                  media?.imageThumbnail !== null &&
                  media?.imageThumbnail !== ""
                    ? media?.imageThumbnail
                    : ""
                }
                path={"storage/media/"}
                type="commercial"
              />
              <div className="result_detail_blk">
                <PropertyDetails
                  property={branch_row}
                  type="commercial"
                  member={member}
                  walkscore={walkscore}
                  property_score={branch_row?.branch_score}
                  latest_expense_row={branch_row?.latest_expense_row}
                />
                {/* <div className="gap_line_divide"></div> */}
                {branch_row?.listings != undefined &&
                branch_row?.listings?.length > 0 ? (
                  <>
                    <CommercialFilter
                      amenities_arr={amenities}
                      features_arr={features}
                      searchListings={searchListings}
                    />
                    <div className="gap_line_divide"></div>

                    {isSearchLoading ? (
                      <h4>Loading</h4>
                    ) : (
                      <PropertyListings
                        listings={searchedListings}
                        branch_row={branch_row}
                        address={branch_row?.address}
                        closePopup={closePopup}
                        showPopup={showPopup}
                        member={member}
                      />
                    )}

                    <hr />
                  </>
                ) : (
                  ""
                )}
                <HouseOperations branch_contact_row={branch_contact_row} />

                <PropertyReviews
                  listing={true}
                  property={branch_row}
                  member={member}
                  showReviewPopup={showReviewPopup}
                />
                {branch_row?.formatted_address !== "" &&
                branch_row?.formatted_address !== null &&
                branch_row?.formatted_address !== undefined ? (
                  <PropertyLocation address={branch_row?.formatted_address} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
