import React, { useState } from 'react'
import Image from './image';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const options = {
    autoplay: false,
    nav: true,
    dots: true,
    dotsEach: 5,
    navText: ['<i class="fi-chevron-left"></i>', '<i class="fi-chevron-right"></i>'],
    loop: true,
    margin: 20,
    smartSpeed: 1000,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    items: 1
};

export default function PropertyGallery({ data, thumbnail, title }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const settings = {
        dots: false, // Show dots when currentIndex is 4 or more
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => setCurrentIndex(index),
    };
    return (
        <>
            <Slider  {...settings}>
                {
                    thumbnail !== undefined && thumbnail !== null && thumbnail !== '' ?
                        <div className="item">
                            <Image src={thumbnail} alt={title} />
                        </div>
                        :
                        ""
                }
                {data?.map((val, index) => {
                    return (
                        <div className="item" key={index}>
                            <Image src={val.image} alt={val.heading} />
                        </div>
                    );
                })}
            </Slider>
        </>
    )
}
