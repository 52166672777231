import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import http from "../../../../helpers/http";
import toast, { Toaster } from "react-hot-toast";
import * as helpers from "../../../../helpers/api";
import Text from "../../../common/Text";
export default function MaintenanceReviewReply({
  review_id,
  profile_id,
  closeReply,
  setReviewsArr,
}) {
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const watchAllFields = watch();
  const onSubmit = async (data) => {
    data = { ...data, m_p_id: profile_id, review_id: review_id };

    setIsFormProcessing(true);
    try {
      const response = await http.post(
        `save-maintenance-review-reply`,
        helpers.doObjToFormData(data)
      );
      setIsFormProcessing(false);
      if (response.data.status) {
        toast.success(response?.data?.msg);
        closeReply();
        setReviewsArr(response?.data?.reviews);
      } else {
        toast.error(<Text string={response.data.msg} parse={true} />);
        return [];
      }
    } catch (error) {
      setIsFormProcessing(false);
      helpers.consoleLog(error);
      return [];
    }
  };
  return (
    <>
      <div className="replyWrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="txtGrp">
            <textarea
              name=""
              id=""
              className="txtBox"
              placeholder="write your message here"
              {...register("reply", {
                required: "Required",
              })}
            ></textarea>
            <ErrorMessage
              errors={errors}
              name="reply"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
          <div className="bTn">
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing}
            >
              Send {isFormProcessing ? <i className="spinner"></i> : ""}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
