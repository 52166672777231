import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { postData } from "../../../../helpers/api";
export default function PropertyHeader({
  address,
  floor_plan_name,
  item,
  member,
  handleDelete,
  listing = false,
  showUpdateListingPopup,
  listing_row,
  showExtendListingPopup,
  property_url,
  handleListingDelete,
  property,
  owner,
  commercial_listing = false,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const createLease = (property) => {
    const newData = { property: property };
    const encodedObject = encodeURIComponent(JSON.stringify(newData));
    searchParams.set("lease_data", encodedObject);
    navigate(`/create-lease?${searchParams.toString()}`);
  };

  const [sharePopup, setSharePopup] = useState(null);
  const [isFavorite, setIsFavorite] = useState(null);
  useEffect(() => {
    if (property?.mem_type === "company") {
      setIsFavorite(parseInt(property?.is_favorite));
    } else {
      setIsFavorite(parseInt(property?.listing?.is_favorite));
    }
  }, [property]);
  const [memberId, setMemberId] = useState(null);
  useEffect(() => {
    if (parseInt(member?.mem_employee) === 1) {
      setMemberId(member?.super_admin);
    } else {
      setMemberId(member?.id);
    }
  }, [member]);

  const showSharePopup = (item) => {
    setSharePopup(item);
    // alert(!popup);
  };
  const closeSharePopup = () => {
    setSharePopup(null);
  };
  const copyText = () => {
    navigator.clipboard.writeText(property_url);
    toast.success("Link copied!", TOAST_SETTINGS);
  };
  const authToken = localStorage.getItem("authToken");
  const addToFavorite = async (listing_id, mem_type) => {
    if (
      (authToken === undefined || authToken === null || authToken === "") &&
      (member === undefined || member === null || member === "")
    ) {
      toast.error(
        "You need to login to add this product in favorites!",
        TOAST_SETTINGS
      );
      return;
    }
    let frmData = {
      listing_id: listing_id,
      mem_type: mem_type,
    };
    await postData("api/add-listing-to-favorite/", frmData).then((res) => {
      if (res?.status === 1) {
        setIsFavorite(parseInt(res?.is_favorite));
        // toast.success(res?.msg,
        //     TOAST_SETTINGS
        // ); return;
      } else {
        toast.error(res?.msg, TOAST_SETTINGS);
        return;
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="view_propert_detail">
        <div className="contain">
          <div className="view_flex flex">
            <div className="property_heading">
              <div className="flex">
                <h2>{floor_plan_name} </h2>
                {property?.listing?.reviews_arr !== undefined &&
                property?.listing?.reviews_arr !== null &&
                property?.listing?.reviews_arr?.length > 0 ? (
                  <div className="detail_review_star">
                    <i className="fa fa-star"></i>
                    <span>
                      5.0 (
                      {item?.listing?.reviews_arr !== undefined
                        ? item?.listing?.reviews_arr?.length
                        : 0}{" "}
                      Reviews)
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <p>{address}</p>
            </div>
            <div className="right_info cstm_mini_search_vertical cta_cell_cell">
              <div className="bTn">
                {listing !== true && listing !== 1 ? (
                  member !== undefined &&
                  member !== null &&
                  memberId === item?.mem_id &&
                  owner === true ? (
                    member?.mem_type === "member" ? (
                      <>
                        <NavLink
                          to="#!"
                          className="webBtn mini_web_btn"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you wish to delete this item?"
                              )
                            )
                              handleDelete(item.id);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </NavLink>
                        <a
                          href={"/edit-property/" + item.id}
                          className="webBtn mini_web_btn"
                        >
                          <i className="fa fa-pencil"></i>
                        </a>

                        <div className="action_drop _dropDown pDetailsDropDown">
                          <NavLink
                            to="#!"
                            className="webBtn mini_web_btn dropBtn"
                          >
                            <i className="fa fa-plus"></i>
                          </NavLink>
                          <ul className="_dropCnt dropLst">
                            <li>
                              <a
                                href="#!"
                                onClick={() =>
                                  createLease(property?.encoded_id)
                                }
                              >
                                + Lease
                              </a>
                            </li>
                            <li>
                              <NavLink to={"/sale-listing/" + item?.slug}>
                                + Sale listing
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={"/rent-listing/" + item?.slug}>
                                + Rental listing
                              </NavLink>
                            </li>
                            <li>
                              <a href="?">+ Delegate Person</a>
                            </li>
                            <li>
                              <a href="?">+ Maintenance Person</a>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : member !== undefined &&
                    member !== null &&
                    memberId === item?.mem_id &&
                    member?.mem_type === "member" &&
                    listing_row?.listing_status === "active" &&
                    listing_row?.expired !== true &&
                    listing_row?.expired !== "true" ? (
                    <>
                      <a
                        href="javascript:void(0)"
                        className="webBtn mini_web_btn"
                        onClick={() => showSharePopup(item)}
                      >
                        <i className="fa fa-share-alt"></i>
                      </a>
                      <NavLink
                        to="#!"
                        className="webBtn mini_web_btn"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            )
                          )
                            handleListingDelete(listing_row?.id);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </NavLink>
                      <NavLink
                        to="#!"
                        className="webBtn mini_web_btn"
                        onClick={() => showUpdateListingPopup(listing_row)}
                      >
                        <i className="fa fa-pencil"></i>
                      </NavLink>
                      <NavLink
                        to="#!"
                        className="webBtn mini_web_btn"
                        onClick={() => showExtendListingPopup(listing_row)}
                      >
                        <i className="fa fa-expand"></i>
                      </NavLink>
                    </>
                  ) : member !== undefined &&
                    member !== null &&
                    memberId === item?.mem_id &&
                    member?.mem_type === "company" ? (
                    <>
                      <a
                        href="javascript:void(0)"
                        className="webBtn mini_web_btn"
                        onClick={() => showSharePopup(item)}
                      >
                        <i className="fa fa-share-alt"></i>
                      </a>
                      {/* <NavLink to="#!" className="webBtn mini_web_btn" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(item?.id) }}><i className="fa fa-trash"></i></NavLink>
                                                        <NavLink to={"/edit-property/" + item?.id} className="webBtn mini_web_btn"><i className="fa fa-pencil"></i></NavLink> */}
                    </>
                  ) : (
                    <>
                      <a href="?" className="webBtn mini_web_btn">
                        <i className="fa fa-exclamation"></i>
                      </a>
                      {property?.mem_type === "company" ? (
                        <a
                          href="#!"
                          className={
                            isFavorite
                              ? "webBtn mini_web_btn blankBtn borderBtn"
                              : "webBtn mini_web_btn"
                          }
                          onClick={() =>
                            addToFavorite(property?.id, property?.mem_type)
                          }
                        >
                          <i
                            className={
                              isFavorite ? "fa fa-heart-o" : "fa fa-heart"
                            }
                          ></i>
                        </a>
                      ) : (
                        <a
                          href="#!"
                          className={
                            isFavorite
                              ? "webBtn mini_web_btn blankBtn borderBtn"
                              : "webBtn mini_web_btn"
                          }
                          onClick={() =>
                            addToFavorite(
                              property?.listing?.listing_id,
                              property?.mem_type
                            )
                          }
                        >
                          <i
                            className={
                              isFavorite ? "fa fa-heart-o" : "fa fa-heart"
                            }
                          ></i>
                        </a>
                      )}

                      <a
                        href="javascript:void(0)"
                        className="webBtn mini_web_btn"
                        onClick={() => showSharePopup(item)}
                      >
                        <i className="fa fa-share-alt"></i>
                      </a>
                    </>
                  )
                ) : commercial_listing === true &&
                  listing_row?.listing_status === "active" ? (
                  <>
                    <a
                      href="javascript:void(0)"
                      className="webBtn mini_web_btn"
                      onClick={() => showSharePopup(item)}
                    >
                      <i className="fa fa-share-alt"></i>
                    </a>
                    <NavLink
                      to="#!"
                      className="webBtn mini_web_btn"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          handleListingDelete(listing_row?.id);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </NavLink>
                    <NavLink
                      to={"/edit-listing/" + listing_row?.id}
                      className="webBtn mini_web_btn"
                    >
                      <i className="fa fa-pencil"></i>
                    </NavLink>
                  </>
                ) : listing_row?.listing_status === "active" &&
                  listing_row?.expired !== true &&
                  listing_row?.expired !== "true" ? (
                  <>
                    <a
                      href="javascript:void(0)"
                      className="webBtn mini_web_btn"
                      onClick={() => showSharePopup(item)}
                    >
                      <i className="fa fa-share-alt"></i>
                    </a>
                    <NavLink
                      to="#!"
                      className="webBtn mini_web_btn"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          handleListingDelete(listing_row?.id);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </NavLink>
                    <NavLink
                      to="#!"
                      className="webBtn mini_web_btn"
                      onClick={() => showUpdateListingPopup(listing_row)}
                    >
                      <i className="fa fa-pencil"></i>
                    </NavLink>
                    <NavLink
                      to="#!"
                      className="webBtn mini_web_btn"
                      onClick={() => showExtendListingPopup(listing_row)}
                    >
                      <i className="fa fa-expand"></i>
                    </NavLink>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {sharePopup !== null && sharePopup !== undefined && sharePopup !== "" ? (
        <section className="popup" data-popup="offer-view">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div
                  className="crosBtn"
                  onClick={(e) => closeSharePopup(e)}
                ></div>
                <h4>Share this link via</h4>
                <div className="content">
                  <ul className="icons">
                    {/* <a href="#"><i className="fa fa-facebook-f"></i></a> */}
                    <FacebookShareButton
                      url={property_url}
                      quote={sharePopup?.popup_title}
                    >
                      <i className="fa fa-facebook-f"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={property_url}
                      title={sharePopup?.popup_title}
                      via={sharePopup?.description}
                    >
                      <i className="fa fa-twitter"></i>
                    </TwitterShareButton>
                    {/* <PinterestShareButton url={property_url} description={sharePopup?.description} media={getServerImage(path, thumbnail)}>
                                                <i className="fa fa-pinterest"></i>
                                            </PinterestShareButton> */}
                    <EmailShareButton
                      url={property_url}
                      subject={sharePopup?.popup_title}
                      body={sharePopup?.description}
                    >
                      <i className="fa fa-envelope"></i>
                    </EmailShareButton>
                    <WhatsappShareButton
                      url={property_url}
                      title={sharePopup?.popup_title}
                      separator={sharePopup?.description}
                    >
                      <i className="fa fa-whatsapp"></i>
                    </WhatsappShareButton>
                    <LinkedinShareButton
                      url={property_url}
                      title={sharePopup?.popup_title}
                      summary={sharePopup?.description}
                      source="Loftus"
                    >
                      <i className="fa fa-linkedin"></i>
                    </LinkedinShareButton>
                  </ul>
                  <p>Or copy link</p>
                  <div className="field txtGrp">
                    <i className="fa fa-link"></i>
                    <input
                      type="text"
                      readonly
                      value={property_url}
                      className="txtBox"
                    />
                    <button className="webBtn smBtn" onClick={() => copyText()}>
                      <i className="fi-copy"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
