import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  format_number,
  format_amount,
  format_date,
} from "../../../../helpers/api";
import PropertyChat from "./chat";
import OpenHouses from "./open-houses";
import AroundHome from "./around-home";
import SchoolSummary from "./school-summary";
import PaymentEstimate from "./payment-estimate";
import ExpenseCostBlk from "../../dashboard-company/expenses/expense-cost-blk";

export default function PropertyDetails({
  property,
  listing = false,
  listing_row,
  member = false,
  property_page = false,
  owner = false,
  type = "member",
  open_houses,
  walkscore,
  property_score = null,
  latest_expense_row = null,
}) {
  const [memberId, setMemberId] = useState(null);
  useEffect(() => {
    if (parseInt(member?.mem_employee) === 1) {
      setMemberId(member?.super_admin);
    } else {
      setMemberId(member?.id);
    }
  }, [member]);
  const [viewOffer, setViewOffer] = useState(false);
  const [viewMsg, setViewMsg] = useState(false);
  const ToggleViewOffer = () => {
    setViewOffer(!viewOffer);
  };
  const ToggleViewMsg = () => {
    setViewMsg(!viewMsg);
  };
  return (
    <>
      <div className="flex main_detail_head">
        <div className="left_info">
          <h3>{type === "commercial" ? property?.name : property?.title}</h3>
          {property?.mem_type === "member" ? (
            <ul className="flex detail_place_estimate">
              {property?.studio === "no" ? (
                <>
                  <li>{property.bedrooms + " Bed"}</li>
                </>
              ) : (
                <li>Studio</li>
              )}
              <li>
                {parseInt(property.full_bathrooms) +
                  parseInt(property.half_bathrooms) +
                  " Bath"}
              </li>
              <li>{format_number(property.sq_feet)} SF</li>
            </ul>
          ) : (
            ""
          )}
          {owner === false ? (
            member === null || listing || type === "commercial" ? (
              <ul className="flex detail_place_estimate">
                <li>
                  {property?.type === "member"
                    ? property?.listing?.total_views !== undefined &&
                      property?.listing?.total_views > 1
                      ? property?.listing?.total_views + " Views"
                      : property?.listing?.total_views !== undefined &&
                        property?.listing?.total_views > 0
                        ? property?.listing?.total_views + " View"
                        : "0 Views"
                    : property?.total_views !== undefined &&
                      property?.total_views > 1
                      ? property?.total_views + " Views"
                      : property?.total_views !== undefined &&
                        property?.total_views > 0
                        ? property?.total_views + " View"
                        : "0 Views"}
                </li>
                <li>
                  {parseInt(property?.total_save) > 0
                    ? parseInt(property?.total_save) > 1
                      ? parseInt(property?.total_save) + " Saves"
                      : parseInt(property?.total_save) + " Save"
                    : "0 Save"}
                </li>
              </ul>
            ) : property_page === true ? (
              <ul className="flex detail_place_estimate">
                <li>
                  {property?.listing?.total_views !== undefined &&
                    property?.listing?.total_views > 1
                    ? property?.listing?.total_views + " Views"
                    : property?.listing?.total_views !== undefined &&
                      property?.listing?.total_views > 0
                      ? property?.listing?.total_views + " View"
                      : "0 Views"}
                </li>
                <li>
                  {parseInt(property?.total_save) > 0
                    ? parseInt(property?.total_save) > 1
                      ? parseInt(property?.total_save) + " Saves"
                      : parseInt(property?.total_save) + " Save"
                    : "0 Save"}
                </li>
              </ul>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        {listing === false ? (
          <div className="right_info cstm_mini_search_vertical">
            <div className="bTn">
              {owner === false ? (
                property?.owner !== true && type !== "commercial" ? (
                  <>
                    {member === null || memberId !== property?.mem_id ? (
                      <>
                        {property?.mem_type === "member" ? (
                          <NavLink
                            to={
                              "/send-user-offer/" +
                              property.slug +
                              "/" +
                              property?.listing?.listing_id
                            }
                            className="mini_btn cstm_mini_search"
                          >
                            <i className="fa fa-paper-plane"></i>{" "}
                            <span>Send Offer</span>
                          </NavLink>
                        ) : (
                          <NavLink
                            to={"/send-offer/" + property.slug}
                            className="mini_btn cstm_mini_search"
                          >
                            <i className="fa fa-paper-plane"></i>{" "}
                            <span>Send Offer</span>
                          </NavLink>
                        )}
                        <a
                          href="javascript:void(0)"
                          onClick={ToggleViewOffer}
                          className="mini_btn cstm_mini_search popBtn"
                          data-popup="offer-view"
                        >
                          <i className="fa fa-eye"></i> <span>View Offers</span>
                        </a>

                        <a
                          href="javascript:void(0)"
                          onClick={ToggleViewMsg}
                          className="mini_btn cstm_mini_search popBtn"
                          data-popup="send-message"
                        >
                          <i className="fa fa-comments"></i> <span>Chat</span>
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : property_page === true ? (
                  <a
                    href="javascript:void(0)"
                    onClick={ToggleViewOffer}
                    className="mini_btn cstm_mini_search popBtn"
                    data-popup="offer-view"
                  >
                    <i className="fa fa-eye"></i> <span>View Offers</span>
                  </a>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        ) : listing === true || listing === 1 ? (
          <div className="right_info cstm_mini_search_vertical">
            <div className="bTn">
              {member !== null && memberId !== listing_row?.mem_id ? (
                <a
                  href="javascript:void(0)"
                  onClick={ToggleViewOffer}
                  className="mini_btn cstm_mini_search popBtn"
                  data-popup="offer-view"
                >
                  <i className="fa fa-eye"></i> <span>View Offers</span>
                </a>
              ) : (
                <>
                  <a
                    href="javascript:void(0)"
                    onClick={ToggleViewOffer}
                    className="mini_btn cstm_mini_search popBtn"
                    data-popup="offer-view"
                  >
                    <i className="fa fa-eye"></i> <span>View Offers</span>
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={ToggleViewMsg}
                    className="mini_btn cstm_mini_search popBtn"
                    data-popup="send-message"
                  >
                    <i className="fa fa-comments"></i> <span>Chat</span>
                  </a>
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {type === "commercial" ? (
          memberId === property?.mem_id ? (
            ""
          ) : (
            <>
              <div className="right_info cstm_mini_search_vertical">
                <div className="bTn">
                  {/* <NavLink to={"/send-offer/" + property.slug} className="mini_btn cstm_mini_search">
                                            <i className="fa fa-paper-plane"></i> <span>Send Offer</span>
                                        </NavLink>
                                        <a href="javascript:void(0)" onClick={ToggleViewOffer} className="mini_btn cstm_mini_search popBtn" data-popup="offer-view">
                                            <i className="fa fa-eye"></i> <span>View Offers</span>
                                        </a> */}

                  <a
                    href="javascript:void(0)"
                    onClick={ToggleViewMsg}
                    className="mini_btn cstm_mini_search popBtn"
                    data-popup="send-message"
                  >
                    <i className="fa fa-comments"></i> <span>Chat</span>
                  </a>
                </div>
              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>

      <hr />
      {property?.mem_type === "member" ? (
        <>
          <h3 className="detail_heading">Property Details</h3>
          <div className="main_feature_list">
            <ul className="flex feature_lising">
              {owner === false ? (
                <>
                  {listing_row?.listing_type === "sale" &&
                    listing_row?.price ? (
                    <li>
                      <h6>Price</h6>
                      <p>{format_amount(listing_row?.price)}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "sale" &&
                    listing_row?.hoa_fee ? (
                    <li>
                      <h6>HOA Fee</h6>
                      <p>{format_amount(listing_row?.hoa_fee)}</p>
                    </li>
                  ) : (
                    ""
                  )}

                  {listing_row?.listing_type === "sale" &&
                    listing_row?.hoa_fee_freq ? (
                    <li>
                      <h6>HOA Fee Frequency</h6>
                      <p>{listing_row?.hoa_fee_freq}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "sale" &&
                    listing_row?.condo_fee ? (
                    <li>
                      <h6>Condo Fee</h6>
                      <p>{format_amount(listing_row?.condo_fee)}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "sale" &&
                    listing_row?.hoa_fee_freq ? (
                    <li>
                      <h6>Condo Fee Frequency</h6>
                      <p>{listing_row?.condo_fee_freq}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "rent" &&
                    listing_row?.rent_price ? (
                    <li>
                      <h6>Rent</h6>
                      <p>{format_amount(listing_row?.rent_price)}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "rent" &&
                    listing_row?.deposit_type ? (
                    <li>
                      <h6>Deposit</h6>
                      <p>{format_amount(listing_row?.deposit_type)}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "rent" &&
                    listing_row?.lease_term ? (
                    <li>
                      <h6>Lease Term</h6>
                      <p>{format_number(listing_row?.lease_term)}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "rent" &&
                    listing_row?.available_date ? (
                    <li>
                      <h6>Available Date</h6>
                      <p>{format_date(listing_row?.available_date)}</p>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing_row?.listing_type === "rent" &&
                    listing_row?.entire_home ? (
                    <li>
                      <h6>Entire Home</h6>
                      <p>{listing_row?.entire_home}</p>
                    </li>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {property?.year ? (
                <li>
                  <h6>Year Built</h6>
                  <p>{property?.year}</p>
                </li>
              ) : (
                ""
              )}
              {property?.lot_size ? (
                <li>
                  <h6>Lot Size</h6>
                  <p>{format_number(property?.lot_size) + " SF"} </p>
                </li>
              ) : (
                ""
              )}
              {property?.property_type ? (
                <li>
                  <h6>Property Type</h6>
                  <p>{property?.property_type} </p>
                </li>
              ) : (
                ""
              )}
              {property?.house_style ? (
                <li>
                  <h6>House Style</h6>
                  <p>{property?.house_style} </p>
                </li>
              ) : (
                ""
              )}
              {property?.nearest_metro ? (
                <li>
                  <h6>Nearest Metro</h6>
                  <p>{format_number(property?.nearest_metro) + " mi"} </p>
                </li>
              ) : (
                ""
              )}
              {property?.laundry ? (
                <li>
                  <h6>Laundry</h6>
                  <p>{property?.laundry} </p>
                </li>
              ) : (
                ""
              )}
              {property?.flooring ? (
                <li>
                  <h6>Flooring</h6>
                  <p>{property?.flooring} </p>
                </li>
              ) : (
                ""
              )}
              {property?.counter_top ? (
                <li>
                  <h6>Countertop Type</h6>
                  <p>{property?.counter_top} </p>
                </li>
              ) : (
                ""
              )}
              {property?.air_conditioning ? (
                <li>
                  <h6>Air Conditioning</h6>
                  <p>{property?.air_conditioning} </p>
                </li>
              ) : (
                ""
              )}
              {property?.heating ? (
                <li>
                  <h6>Heating</h6>
                  <p>{property?.heating} </p>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          <hr />
        </>
      ) : (
        ""
      )}

      <h3 className="detail_heading">Description</h3>
      <p>{property?.description}</p>
      <hr />
      {/* ==============new work============== */}
      {open_houses?.length > 0 ? (
        <>
          <h3 className="detail_heading">Open House</h3>
          <OpenHouses open_houses={open_houses} />
          <hr />
        </>
      ) : (
        ""
      )}
      {/* ===================end new work=============== */}
      {property?.features != undefined && property?.features?.length > 0 ? (
        <>
          <h3 className="detail_heading">Features</h3>
          <ul className="flex icon_listing">
            {property?.features?.map((feature, index) => {
              return <li key={index}>{feature.name}</li>;
            })}
          </ul>
          <hr />
        </>
      ) : (
        ""
      )}
      {property?.amenities != undefined && property?.amenities?.length > 0 ? (
        <>
          <h3 className="detail_heading">Amenities</h3>
          <ul className="flex icon_listing">
            {property.amenities?.map((am, index) => {
              return <li key={index}>{am.name}</li>;
            })}
          </ul>
          <hr />
        </>
      ) : (
        ""
      )}
      {/* ====expenses======== */}

      <div class="gap_line_divide"></div>
      {/* ====end expenses======== */}
      {owner === false ? (
        <>
          {/* ==============new work============== */}
          <div className="around_home_school flex">
            {property_score?.length > 0 ? (
              <div className="colL">
                <AroundHome
                  property_score={property_score}
                  property_address={property?.formatted_address}
                />
              </div>
            ) : (
              ""
            )}
            <div className="colR">
              <SchoolSummary />
            </div>
          </div>

          <hr />
        </>
      ) : (
        ""
      )}
      {property?.mem_type === "company" ? (
        latest_expense_row?.id > 0 ? (
          <>
            <div className="expenses_flex search_expense_com">
              <ExpenseCostBlk
                costs={latest_expense_row?.one_time_costs}
                title="One-Time Expenses"
              />
              <ExpenseCostBlk
                costs={latest_expense_row?.recurring_costs}
                title="Recurring Expenses"
              />
              <ExpenseCostBlk
                costs={latest_expense_row?.parking_costs}
                title="Parking Expenses"
              />
            </div>
            <hr />
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {listing_row?.listing_type === "sale" ? (
        <>
          <div className="payment_estimate">
            <PaymentEstimate listing_row={listing_row} />
          </div>
          <hr />
        </>
      ) : (
        ""
      )}
      {/* ===================end new work=============== */}

      <section
        className={
          viewOffer
            ? "popup small_popup view_offer_popup active"
            : "popup small_popup view_offer_popup"
        }
        data-popup="offer-view"
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={ToggleViewOffer} />
              <h4>All offers</h4>
              <div className="blk blockLst cell_block_cell">
                {property?.view_offers?.length > 0 ? (
                  property?.listing?.listing_type === "sale" ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Contingencies</th>
                        </tr>
                      </thead>
                      <tbody>
                        {property?.view_offers?.map((offer_row, index) => {
                          return (
                            <tr>
                              <td>{offer_row?.price}</td>
                              <td>{offer_row?.contingencies}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Term</th>
                          <th>Move-in Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {property?.view_offers?.map((offer_row, index) => {
                          return (
                            <tr>
                              <td>{offer_row?.price}</td>
                              <td>{offer_row?.term}</td>
                              <td>{offer_row?.move_in_date}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )
                ) : (
                  <div className="alert alert-danger">No offers available.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {viewMsg ? (
        <PropertyChat
          ToggleViewMsg={ToggleViewMsg}
          property_mem_id={property?.property_mem_id}
        />
      ) : (
        ""
      )}
    </>
  );
}
