import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_USER_FAVORITES_CONTENT,
    FETCH_USER_FAVORITES_CONTENT_SUCCESS,
    FETCH_USER_FAVORITES_CONTENT_FAILED,
    USER_FAVORITES_DELETE__MESSAGE,
    USER_FAVORITES_DELETE_MESSAGE_SUCCESS,
    USER_FAVORITES_DELETE__MESSAGE_FAILED,
} from "./actionTypes";

export const fetchUserFavorites = () => (dispatch) => {
    dispatch({
        type: FETCH_USER_FAVORITES_CONTENT,
        payload: null
    });
    http
        .get("user-favorites")
        .then(({ data }) => {
            dispatch({
                type: FETCH_USER_FAVORITES_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_USER_FAVORITES_CONTENT_FAILED,
                payload: error
            });
        });
};


export const deleteUserPropertyFavorite = (formData) => (dispatch) => {
    dispatch({
        type: USER_FAVORITES_DELETE__MESSAGE,
        payload: null
    });
    http
        .post('add-listing-to-favorite/', helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                // toast.success(
                //     `${data.msg}`,
                //     TOAST_SETTINGS
                // );
                setTimeout(() => {
                    window.location.replace("/favorites");
                }, 2000);
                dispatch({
                    type: USER_FAVORITES_DELETE_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: USER_FAVORITES_DELETE__MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: USER_FAVORITES_DELETE__MESSAGE_FAILED,
                payload: error
            });
        });
};
